import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import add from "../images/add.png";
import apply from "../images/apply.png";
import select from "../images/select.png";
import logout from "../images/logout.png";
import joblist from "../images/joblist.png";
import axios from "axios";
import config from "../functions/config";
import Notification from "../components/Notification";
import pro_pic from "../images/pro_pic.jpeg";

export default function Jobuserapply() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [array, setArray] = useState([]);
  const [job, setJob] = useState([]);

  const [user, setUser] = useState([]);
  const [user1, setUser1] = useState([]);

  const [notificationCount, setNotificationCount] = useState(0);
  const [user2, setUser2] = useState([]);
  const [notification, setNotification] = useState([]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobapp`, {
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        const res2 = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response);
          setArray(response.data.data);
          // setUser(response.data.data.user)
          setUser1(response.data.user_id);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
          setUser(res1.data.user);
        } else {
          console.log("error 2");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setNotification(res2.data.data);
          setUser2(res2.data.user_id);
          const count = res2.data.data.filter(
            (item) => item.user === res2.data.user_id && item.is_read === false
          );
          setNotificationCount(count.length);
        } else {
          console.log("error 5");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token]);

  return (
    <div className="min-h-screen">
      <div className="relative">
        <div className="fixed flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <div className="flex items-center gap-3">
            <div className="relative">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggleNotification();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                  />
                </svg>

                <span className="absolute top-0 right-0 left-4 block w-4 h-4 text-xs text-white bg-red-600 rounded-full text-center">
                  {notificationCount}
                </span>
              </button>
            </div>

            <h5 className="text-3xl font-bold text-white">Careerhub</h5>
          </div>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out fixed top-16 left-0 w-36 h-60 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            {/* <li>
            <Link><button onClick={(e)=>{e.preventDefault(); toggleNotification();}}>Notifications</button>
                  
                  </Link>
            </li> */}

            <li>
              <Link to="/jobscopy">Jobs</Link>
            </li>
            <li>
              <Link to="/jobuserapply">Applied</Link>
            </li>
            {/* <li>
              <Link to="/jobuserselected">Jobs Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className=" flex bg-slate-200 min-h-screen">
        <nav>
          <div className="fixed top-0 left-0 bottom-0 hidden md:block sm:w-[200px] md:w-[280px] h-full bg-violet-600">
            <div className="flex items-center p-5">
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={profile} width={30} height={30} /> */}

                {/* <a href="/userprofile" className="ml-5">
                  Profile
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>

              {/* <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                  />
                </svg>

                <Link className="ml-5 font-bold text-white">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleNotification();
                    }}
                  >
                    Notifications
                  </button>
                </Link>
              </li> */}

              {/* <li className='flex items-center mt-7 px-8'>
                            <img src={profile}  width={30} height={30} />
                            <a href='/myjobs' className='ml-5'>Jobs</a>
                        </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={add} width={30} height={30} /> */}

                {/* <a href="/jobs" className="ml-5">
                  Jobs
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobscopy" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}

                {/* <a href="/jobuserapply" className="ml-5">
                  Jobs Applied
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobuserapply" className="ml-5 font-bold text-white">
                  Applied
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link
                  to="/jobuserselected"
                  className="ml-5 font-bold text-white"
                >
                  Jobs Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={logout} width={30} height={30} /> */}

                {/* <a href="/" className="ml-5">
                  Logout
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* <div className="bg-slate-200 w-full min-h-screen ">
          <h1 className="text-center text-violet-600 font-bold mt-5">
            APPLIED JOBS
          </h1>
          <ul className="flex flex-wrap gap-6 p-6">

          {console.log(array.filter(item => item.user === user1))}

            {array
              .filter(
                (item) => item.user === user1 && item.is_applied === true && item.is_accepted === false)
              .map((item) => {
                return (
                  <li
                    key={item.id}
                    className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 bg-white shadow-lg rounded-lg"
                  >
                    <h1 className="text-xl font-semibold text-gray-800">
                      {job.find((itm) => itm.id === item.job).title}
                    </h1>
                    <h2 className="text-md text-gray-600">
                      {job.find((itm) => itm.id === item.job).company}
                    </h2>

                    <div className="flex flex-wrap gap-2">
                      <div className="flex items-center gap-1 bg-slate-100 px-1 py-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5 text-gray-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>

                        <h3 className="text-sm font-medium text-gray-500 mb-1">
                          {job.find((itm) => itm.id === item.job).salary} per
                          month
                        </h3>
                      </div>

                      <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5 text-gray-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                          />
                        </svg>
                        <h3 className="text-sm font-medium text-gray-500 mb-2">
                          {job.find((itm) => itm.id === item.job).job_type}
                        </h3>
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button className="bg-violet-600 px-2 py-1 mt-3 text-white font-semibold rounded-3xl">
                        <Link to={`/jobuserapplydetails/${item.id}`}>
                          View Application
                        </Link>
                      </button>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div> */}

        <div className=" w-full">
          <div className="hidden md:block w-full px-4 py-5">
            <div className="fixed top-0 left-0 right-3 flex justify-end items-center gap-3 z-10">
              <div className="mr-3">
                <button
                  // onClick={() => navigate("/notification")}

                  onClick={(e) => {
                    e.preventDefault();
                    toggleNotification();
                  }}
                >
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 text-violet-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                      />
                    </svg>

                    <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
                      {notificationCount}
                    </span>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <img
                    // src={`${baseUrl}${user.image}`}
                    src={user.image ? `${baseUrl}${user.image}` : pro_pic}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="mt-20 md:mt-2 md:ml-72 bg-slate-200">
            <h1 className="text-center text-violet-600 font-bold mt-2">
              APPLIED JOBS
            </h1>
            <ul className="flex flex-wrap gap-6 p-6">
              {/* Conditional rendering for empty list */}
              {array.filter(
                //   (item) => item.user === user1 && item.is_applied === true && item.is_accepted === false
                // ).length === 0 ? (
                (item) => item.user === user1 && item.is_applied === true
              ).length === 0 ? (
                <li className="w-full text-center mt-11 font-medium text-violet-800">
                  No jobs applied
                </li>
              ) : (
                array
                  .filter(
                    // (item) => item.user === user1 && item.is_applied === true && item.is_accepted === false
                    (item) => item.user === user1 && item.is_applied === true
                  )
                  .map((item) => {
                    let statusText = "Pending"; // Default status

                    if (item.is_accepted) {
                      statusText = "Accepted";
                    } else if (item.is_rejected) {
                      statusText = "Rejected";
                    }

                    return (
                      <li
                        key={item.id}
                        className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 mx-auto bg-white shadow-lg rounded-lg"
                      >
                        <h1 className="text-xl font-semibold text-gray-800">
                          {job.find((itm) => itm.id === item.job).title}
                        </h1>
                        <h2 className="text-md text-gray-600">
                          {job.find((itm) => itm.id === item.job).company}
                        </h2>

                        <div className="flex flex-wrap gap-2">
                          <div className="flex items-center gap-1 bg-slate-100 px-1 py-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-5 text-gray-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                            <h3 className="text-sm font-medium text-gray-500 mb-1">
                              {job.find((itm) => itm.id === item.job).salary}{" "}
                              per month
                            </h3>
                          </div>

                          <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-3 text-gray-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                              />
                            </svg>
                            <h3 className="text-sm font-medium text-gray-500 mb-2">
                              {job.find((itm) => itm.id === item.job).job_type}
                            </h3>
                          </div>
                        </div>

                        {/* Display the status */}
                        <div className=" flex mx-auto mt-3 gap-3">
                          <h3>Status : </h3>
                          <h3
                            className={`font-semibold ${
                              statusText === "Accepted"
                                ? "text-green-500"
                                : statusText === "Rejected"
                                ? "text-red-500"
                                : "text-yellow-500"
                            }`}
                          >
                            {statusText}
                          </h3>
                        </div>

                        <div className="flex justify-center">
                          <button className="bg-violet-600 px-2 py-1 mt-3 text-white font-semibold rounded-3xl">
                            <Link to={`/jobuserapplydetails/${item.id}`}>
                              View Application
                            </Link>
                          </button>
                        </div>

                        {/* <div className="flex items-center gap-5">
                <h2>Status :</h2>
                <h3></h3>
              </div> */}
                      </li>
                    );
                  })
              )}
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`hidden md:block fixed top-10 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>

      <div
        className={`md:hidden fixed top-11 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>
    </div>
  );
}
