import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import config from "../functions/config";
import pro_pic from "../images/pro_pic.jpeg";
import Notification from "./Notification";

export default function Jobadd() {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [job_type, setJob_type] = useState("");
  const [description, setDescription] = useState("");
  const [department, setDepartment] = useState("");
  const [shift, setShift] = useState("");
  const [required_skills, setRequired_skills] = useState("");
  const [experience_required, setExperience_required] = useState("");
  const navigate = useNavigate("");

  const [other_department, setother_Department] = useState(""); // state for other department input

  const [loading, setLoading] = useState(false);

  const [notificationCount, setNotificationCount] = useState(0);
  const [user2, setUser2] = useState([]);
  const [notification, setNotification] = useState([]);

  const [user, setUser] = useState([]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const shiftOptions = ["Day Shift", "Night Shift"];

  // const experienceOptions = [
  //   { value: "", label: "Select Experience" },
  //   { value: "Freshers", label: "Freshers" },
  //   { value: "0", label: "0 Years" },
  //   { value: "1", label: "1 Year" },
  //   { value: "2", label: "2 Years" },
  // ];

  const experienceOptions = [
    { value: "", label: "Select Experience" },
    { value: "Freshers", label: "Freshers" },
    { value: "2", label: "1-2 Years" },
    { value: "4", label: "3-4 Years" },
    { value: "6", label: "5-6 Years" },
    { value: "8", label: "7-8 Years" },
    { value: "10", label: "9-10 Years" },
    { value: "Above10", label: "Above 10 Years" },
    
  ];

  const jobTypeOptions = [
    { value: "", label: "Select Job Type" },
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
    { value: "Temporary", label: "Temporary" },
  ];

  const departmentOptions = [
    { value: "", label: "Select Department" },
    { value: "IT", label: "IT" },
    { value: "Banking", label: "Banking" },
    { value: "Accounting", label: "Accounting" },
    { value: "Nursing", label: "Nursing" },
    { value: "Others", label: "Others" },
  ];

  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        const res6 = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response);
          setUser(response.data.user);
        } else {
          console.log("error 1");
        }

        if (res6.data.status === 200) {
          console.log(res6);
          setNotification(res6.data.data);
          setUser2(res6.data.user_id);
          const count = res6.data.data.filter(
            (item) =>
              item.user === res6.data.user_id &&
              item.is_read === false &&
              item.description === "Jobapplication submitted"
          );
          setNotificationCount(count.length);
        } else {
          console.log("error 7");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token]);

  // const handleSubmit = async (id) => {

  //    // Show loading toast
  // const loadingToast = toast.loading("Loading, Please Wait...", {
  //   autoClose: false,  // Disable autoClose for loading toast
  //   position: "top-right",
  // });

  //   setLoading(true)
  //   const data = {
  //     title: title,
  //     company: company,
  //     salary: salary,
  //     // location: location,
  //     address:address,
  //     city:city,
  //     description: description,
  //     job_type: job_type,
  //     department: department,
  //     shift: shift,
  //     required_skills: required_skills,
  //     experience_required: experience_required,
  //   };
  //   try {
  //     // const response = await axios.post(`${baseUrl}api/v1/ex1/jobs/add`, data, {

  //     const res1 = await axios.post(
  //       `${config.baseUrl}api/v1/ex1/jobs/add`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );

  //     console.log(res1)
  //     if (res1.data.status === 200) {

  //       // Dismiss the loading toast after 1 second (autoClose behavior)
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast);
  //     }, 1000);

  //       console.log(res1);
  //       setLoading(false)
  //       toast.success("Job Added Successfully", {
  //         autoclose: 1000,
  //         position: "top-right",
  //         onClose: () => {
  //           navigate("/employerd");
  //         },
  //       });
  //     } else {

  //       console.log("error 1");
  //       toast.error("Error", {
  //         autoclose: 1000,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error");
  //     toast.error("Error", {
  //       autoclose: 1000,
  //       position: "top-right",
  //     });
  //   }
  // };

  // const handleSubmit = async (id) => {
  //   // Show loading toast and store the toast ID to dismiss later
  //   const loadingToast = toast.loading("Loading, Please Wait...", {
  //     autoClose: false,  // Disable autoClose for loading toast
  //     position: "top-right",
  //   });

  //   setLoading(true);

  //   const data = {
  //         title: title,
  //         company: company,
  //         salary: salary,
  //         // location: location,
  //         address:address,
  //         city:city,
  //         description: description,
  //         job_type: job_type,
  //         department: department,
  //         shift: shift,
  //         required_skills: required_skills,
  //         experience_required: experience_required,
  //       };

  //   try {
  //     const res1 = await axios.post(
  //       `${config.baseUrl}api/v1/ex1/jobs/add`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );

  //     console.log(res1);

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     if (res1.data.status === 200) {
  //       // Show success toast
  //       toast.success("Job Added Successfully", {
  //         autoClose: 1000,
  //         position: "top-right",
  //         onClose: () => {
  //           navigate("/employerd");
  //         },
  //       });
  //     } else {
  //       // Show error toast if status is not 200
  //       toast.error("Error occurred", {
  //         autoClose: 1000,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error");

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     // Show error toast in case of a catch block error
  //     toast.error("Error occurred", {
  //       autoClose: 1000,
  //       position: "top-right",
  //     });
  //   } finally {
  //     setLoading(false); // End loading state
  //   }
  // };

  // const handleSubmit = async (id) => {
  //   // Check if all required fields are filled
  //   if (
  //     !title ||
  //     !company ||
  //     !salary ||
  //     !address ||
  //     !city ||
  //     !description ||
  //     !job_type ||
  //     !department ||
  //     !shift ||
  //     !required_skills ||
  //     !experience_required
  //   ) {
  //     toast.error("Please fill all the required fields!", {
  //       autoClose: 2000,
  //       position: "top-right",
  //     });
  //     return; // Stop further execution if any required field is empty
  //   }

  //   // Show loading toast and store the toast ID to dismiss later
  //   const loadingToast = toast.loading("Loading, Please Wait...", {
  //     autoClose: false, // Disable autoClose for loading toast
  //     position: "top-right",
  //   });

  //   setLoading(true);

  //   const data = {
  //     title: title,
  //     company: company,
  //     salary: salary,
  //     address: address,
  //     city: city,
  //     description: description,
  //     job_type: job_type,
  //     department: department,
  //     shift: shift,
  //     required_skills: required_skills,
  //     experience_required: experience_required,
  //   };

  //   try {
  //     const res1 = await axios.post(
  //       `${config.baseUrl}api/v1/ex1/jobs/add`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );

  //     console.log(res1);

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     if (res1.data.status === 200) {
  //       // Show success toast
  //       toast.success("Job Added Successfully", {
  //         autoClose: 1000,
  //         position: "top-right",
  //         onClose: () => {
  //           navigate("/employerd");
  //         },
  //       });
  //     } else {
  //       // Show error toast if status is not 200
  //       toast.error("Error occurred", {
  //         autoClose: 1000,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error");

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     // Show error toast in case of a catch block error
  //     toast.error("Error occurred", {
  //       autoClose: 1000,
  //       position: "top-right",
  //     });
  //   } finally {
  //     setLoading(false); // End loading state
  //   }
  // };

  // const handleSubmit = async (id) => {
  //   // Create an array to store missing or invalid fields
  //   const missingFields = [];
  //   const invalidFields = [];

  //   // Create regex for validation
  //   const textOnlyRegex = /^[A-Za-z\s]+$/; // For fields like title, company, and city
  //   const alphanumericWithTextRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For description, address, and required_skills
  //   // const addressRegex = /^[A-Za-z0-9\s,.-/]+$/; // For address to allow alphanumeric, spaces, commas, periods, hyphens, slashes
  //   const addressRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For address to ensure it has text and numbers (e.g., "100 feet", "5th street")

  //   // Check for missing fields and add them to the missingFields array
  //   if (!title) missingFields.push('Title');
  //   if (!company) missingFields.push('Company');
  //   if (!salary) missingFields.push('Salary');
  //   if (!address) missingFields.push('Address');
  //   if (!city) missingFields.push('City');
  //   if (!description) missingFields.push('Description');
  //   if (!job_type) missingFields.push('Job Type');
  //   if (!department) missingFields.push('Department');
  //   if (!shift) missingFields.push('Shift');
  //   if (!required_skills) missingFields.push('Required Skills');
  //   if (!experience_required) missingFields.push('Experience Required');

  //   // Validate title and company (only letters and spaces)
  //   if (title && !textOnlyRegex.test(title)) {
  //     invalidFields.push('Title (only letters and spaces allowed)');
  //   }
  //   if (company && !textOnlyRegex.test(company)) {
  //     invalidFields.push('Company (only letters and spaces allowed)');
  //   }

  //   // Validate city (only letters and spaces)
  //   if (city && !textOnlyRegex.test(city)) {
  //     invalidFields.push('City (only letters and spaces allowed)');
  //   }

  //   // Validate description, address, and required_skills (must contain text or number with text and allow specific symbols)
  //   if (description && !alphanumericWithTextRegex.test(description)) {
  //     invalidFields.push('Description (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)');
  //   }
  //   if (required_skills && !alphanumericWithTextRegex.test(required_skills)) {
  //     invalidFields.push('Required Skills (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)');
  //   }

  //   // Validate address (alphanumeric, spaces, commas, periods, hyphens, and slashes)
  //   if (address && !addressRegex.test(address)) {
  //     invalidFields.push('Address (must be a valid address format with letters and numbers)');
  //   }

  //   // If there are missing fields or invalid text fields, show a toast with the errors
  //   if (missingFields.length > 0 || invalidFields.length > 0) {
  //     let errorMessage = "";

  //     if (missingFields.length > 0) {
  //       errorMessage += `Please fill the following fields: ${missingFields.join(", ")}. `;
  //     }

  //     if (invalidFields.length > 0) {
  //       errorMessage += `${invalidFields.join(", ")}.`;
  //     }

  //     toast.error(errorMessage, {
  //       autoClose: 5000,
  //       position: "top-right",
  //     });
  //     return; // Stop further execution if any required field is empty or invalid
  //   }

  //   // Show loading toast and store the toast ID to dismiss later
  //   const loadingToast = toast.loading("Loading, Please Wait...", {
  //     autoClose: false, // Disable autoClose for loading toast
  //     position: "top-right",
  //   });

  //   setLoading(true);

  //   const data = {
  //     title: title,
  //     company: company,
  //     salary: salary,
  //     address: address,
  //     city: city,
  //     description: description,
  //     job_type: job_type,
  //     department: department,
  //     shift: shift,
  //     required_skills: required_skills,
  //     experience_required: experience_required,
  //   };

  //   try {
  //     const res1 = await axios.post(
  //       `${config.baseUrl}api/v1/ex1/jobs/add`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );

  //     console.log(res1);

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     if (res1.data.status === 200) {
  //       // Show success toast
  //       toast.success("Job Added Successfully", {
  //         autoClose: 1000,
  //         position: "top-right",
  //         onClose: () => {
  //           navigate("/employerd");
  //         },
  //       });
  //     } else {
  //       // Show error toast if status is not 200
  //       toast.error("Error occurred", {
  //         autoClose: 1000,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error");

  //     // Dismiss the loading toast after 1 second
  //     setTimeout(() => {
  //       toast.dismiss(loadingToast); // Dismiss the loading toast
  //     }, 1000);

  //     // Show error toast in case of a catch block error
  //     toast.error("Error occurred", {
  //       autoClose: 1000,
  //       position: "top-right",
  //     });
  //   } finally {
  //     setLoading(false); // End loading state
  //   }
  // };

  const handleSubmit = async (id) => {
    // Create an array to store missing or invalid fields
    const missingFields = [];
    const invalidFields = [];

    // Create regex for validation
    const textOnlyRegex = /^[A-Za-z\s]+$/; // For fields like title, company, and city
    const alphanumericWithTextRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For description, address, and required_skills
    const addressRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For address to ensure it has text and numbers (e.g., "100 feet", "5th street")

    // Check for missing fields and add them to the missingFields array
    if (!title) missingFields.push("Title");
    if (!company) missingFields.push("Company");
    if (!salary) missingFields.push("Salary");
    if (!address) missingFields.push("Address");
    if (!city) missingFields.push("City");
    if (!description) missingFields.push("Description");
    if (!job_type) missingFields.push("Job Type");
    if (!department) missingFields.push("Department");
    if (!shift) missingFields.push("Shift");
    if (!required_skills) missingFields.push("Required Skills");
    if (!experience_required) missingFields.push("Experience Required");

    // Additional check for `other_department` if department is "Others"
  if (department === "Others" && (!other_department || !alphanumericWithTextRegex.test(other_department))) {
    invalidFields.push("Other Department (must contain text or alphanumeric characters)");
  }

    // Validate title and company (only letters and spaces)
    if (title && !alphanumericWithTextRegex.test(title)) {
      invalidFields.push(
        "Title (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }
    if (company && !alphanumericWithTextRegex.test(company)) {
      invalidFields.push(
        "Company (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }

    // Validate city (only letters and spaces)
    if (city && !textOnlyRegex.test(city)) {
      invalidFields.push("City (only letters and spaces allowed)");
    }

    // Validate description, address, and required_skills (must contain text or number with text and allow specific symbols)
    if (description && !alphanumericWithTextRegex.test(description)) {
      invalidFields.push(
        "Description (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }
    if (required_skills && !alphanumericWithTextRegex.test(required_skills)) {
      invalidFields.push(
        "Required Skills (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }

    // Validate address (alphanumeric, spaces, commas, periods, hyphens, and slashes)
    if (address && !addressRegex.test(address)) {
      invalidFields.push(
        "Address (must be a valid address format with letters and numbers)"
      );
    }

    // If there are missing fields or invalid text fields, show a toast with the errors
    if (missingFields.length > 0 || invalidFields.length > 0) {
      let errorMessage = "";

      if (missingFields.length > 0) {
        errorMessage += `Please fill the following fields: ${missingFields.join(
          ", "
        )}. `;
      }

      if (invalidFields.length > 0) {
        errorMessage += `${invalidFields.join(", ")}.`;
      }

      toast.error(errorMessage, {
        autoClose: 5000,
        position: "top-right",
      });
      return; // Stop further execution if any required field is empty or invalid
    }

    // Show loading toast and store the toast ID to dismiss later
    const loadingToast = toast.loading("Loading, Please Wait...", {
      autoClose: false, // Disable autoClose for loading toast
      position: "top-right",
    });

    setLoading(true);

    const data = {
      title: title,
      company: company,
      salary: salary,
      address: address,
      city: city,
      description: description,
      job_type: job_type,
      department: department,
      other_department: other_department,
      shift: shift,
      required_skills: required_skills,
      experience_required: experience_required,
    };

    try {
      const res1 = await axios.post(
        `${config.baseUrl}api/v1/ex1/jobs/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      console.log(res1);

      // Dismiss the loading toast after 1 second
      setTimeout(() => {
        toast.dismiss(loadingToast); // Dismiss the loading toast
      }, 1000);

      if (res1.data.status === 200) {
        // Show success toast
        toast.success("Job Added Successfully", {
          autoClose: 1000,
          position: "top-right",
          onClose: () => {
            navigate("/employerd");
          },
        });
      } else {
        // Show error toast if status is not 200
        toast.error("Error occurred", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");

      // Dismiss the loading toast after 1 second
      setTimeout(() => {
        toast.dismiss(loadingToast); // Dismiss the loading toast
      }, 1000);

      // Show error toast in case of a catch block error
      toast.error("Error occurred", {
        autoClose: 1000,
        position: "top-right",
      });
    } finally {
      setLoading(false); // End loading state
    }
  };

  return (
    <div className="">
      {/* <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div> */}

      <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4 fixed top-0 left-0 right-0">
        <button
          onClick={(e) => {
            e.preventDefault();
            toggleMenu();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <div className="flex items-center gap-3">
          <div className="relative">
            <button
              onClick={(e) => {
                e.preventDefault();
                toggleNotification();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-7 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                />
              </svg>

              <span className="absolute top-0 right-0 left-4 block w-4 h-4 text-xs text-white bg-red-600 rounded-full text-center">
                {notificationCount}
              </span>
            </button>
          </div>

          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>
      </div>

      <div
        className={`md:hidden ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out fixed top-[69px] left-0 w-36 h-62 bg-violet-600 z-50`}
      >
        <ul className="space-y-2 px-4 py-4 text-[16px] text-white font-bold">
          <li>
            <Link to="/userprofile">Profile</Link>
          </li>
          <li>
            <Link to="/employerd">Jobs</Link>
          </li>
          <li>
            <Link to="/jobs/add">Add Jobs</Link>
          </li>
          <li>
            <Link to="/jobapplied">Applications</Link>
          </li>
          {/* <li>
              <Link to="/jobselected">Selected</Link>
            </li> */}
          <li>
            <Link to="/">Logout</Link>
          </li>
        </ul>
      </div>

      {/* <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-400`}
          >
          <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/employerd">Jobs</Link>
            </li>
            <li>
              <Link to="/jobs/add">Add Jobs</Link>
            </li>
            <li>
              <Link to="/jobapplied">Applications</Link>
            </li>
            <li>
              <Link to="/jobselected">Applications Selected</Link>
            </li>
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div> */}

      <div className="flex bg-slate-100 ">
        <nav className="">
          <div className="hidden md:block w-56 md:w-[280px]  bg-violet-600 fixed top-0 left-0 bottom-0 ">
            <div className="flex items-center p-5">
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/employerd" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <Link to="/jobs/add" className="ml-5 font-bold text-white">
                  Add Jobs
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobapplied" className="ml-5 font-bold text-white">
                  Applications
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link to="/jobselected" className="ml-5 font-bold text-white">
                  Applications Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="w-full">
          <div className="hidden md:block w-full px-4 py-4">
            <div className="flex justify-end items-center gap-3">
              <div className="mr-3">
                <button
                  // onClick={() => navigate("/notification")}

                  onClick={(e) => {
                    e.preventDefault();
                    toggleNotification();
                  }}
                >
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 text-violet-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                      />
                    </svg>

                    <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
                      {notificationCount}
                    </span>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <img
                    // src={`${baseUrl}${user.image}`}
                    src={user.image ? `${baseUrl}${user.image}` : pro_pic}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-5 mb-5 mx-auto w-full">
            <form
              encType="multipart/form-data"
              // className="bg-white p-8 rounded-lg shadow-lg sm:w-[300px] ml-3 mr-3 md:w-[480px] lg:w-[600px] transition-transform transform hover:scale-105"
              className="bg-white p-8 rounded-lg shadow-lg w-full sm:w-[500px] ml-3 mr-3 md:ml-48 md:w-[380px] lg:w-[600px] mt-16 md:mt-0"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <h2 className="text-2xl font-bold text-center text-violet-600 mb-6">
                Add Job
              </h2>

              <div className="mb-6">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-800"
                >
                  Title :
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter the job title"
                  value={title}
                  onChange={(e) => {
                    e.preventDefault();
                    setTitle(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="Company"
                  className="block text-sm font-medium text-gray-800"
                >
                  Company :
                </label>
                <input
                  type="text"
                  id="company"
                  placeholder="Enter the company name"
                  value={company}
                  onChange={(e) => {
                    e.preventDefault();
                    setCompany(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-800"
                >
                  Description :
                </label>
                <input
                  type="text"
                  id="description"
                  placeholder="Enter the description"
                  value={description}
                  onChange={(e) => {
                    e.preventDefault();
                    setDescription(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-800">
                  Job Type
                </label>
                <select
                  value={job_type}
                  onChange={(e) => setJob_type(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
                >
                  {jobTypeOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="salary"
                  className="block text-sm font-medium text-gray-800"
                >
                  Salary:
                </label>
                <input
                  type="number"
                  id="salary"
                  placeholder="Enter the salary"
                  value={salary}
                  onChange={(e) => {
                    e.preventDefault();
                    setSalary(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700 "
                >
                  Address:
                </label>
                <input
                  id="address"
                  type="text"
                  placeholder="Enter the address"
                  value={address}
                  onChange={(e) => {
                    e.preventDefault();
                    setAddress(e.target.value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 bg-gray-50"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700 "
                >
                  City:
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="Enter the city"
                  value={city}
                  onChange={(e) => {
                    e.preventDefault();
                    setCity(e.target.value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 bg-gray-50"
                />
              </div>

              {/* <div className="mb-6">
              <label className="block text-sm font-medium text-gray-800">
                Department
              </label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
              >
                {departmentOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div> */}

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-800">
                  Department
                </label>
                <select
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    if (e.target.value !== "Others") {
                      setother_Department(""); // Reset 'Other Department' if not "Others"
                    }
                  }}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
                >
                  {departmentOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                {/* Conditionally display 'otherDepartment' value next to 'Others' */}
                {department === "Others" && other_department && (
                  <span className="ml-2 text-gray-800">{other_department}</span>
                )}
              </div>

              {/* Conditionally render "Other Department" input */}
              {department === "Others" && (
                <div className="mb-6">
                  <label
                    htmlFor="other_department"
                    className="block text-sm font-medium text-gray-800"
                  >
                    Other Department:
                  </label>
                  <input
                    type="text"
                    id="other_department"
                    placeholder="Enter the department name"
                    value={other_department}
                    onChange={(e) => setother_Department(e.target.value)}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                  />
                </div>
              )}

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-800">
                  Shift
                </label>
                <select
                  value={shift}
                  onChange={(e) => setShift(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2"
                >
                  <option value="">Select Shift</option>
                  {shiftOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-800">
                  Experience Required
                </label>
                <select
                  value={experience_required}
                  onChange={(e) => setExperience_required(e.target.value)}
                  // className="w-full border-2 border-gray-300 p-2 rounded mt-2 max-h-10 overflow-y-auto"
                  className="w-full border-2 border-gray-300 p-1 text-sm rounded mt-2 max-h-40 overflow-y-auto"
                >
                  {experienceOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="required_skills"
                  className="block text-sm font-medium text-gray-800"
                >
                  Required_Skills :
                </label>
                <input
                  type="text"
                  id="required_skills"
                  placeholder="Enter the required skills"
                  value={required_skills}
                  onChange={(e) => {
                    e.preventDefault();
                    setRequired_skills(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`hidden md:block fixed top-10 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>

      <div
        className={`md:hidden fixed top-11 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>
    </div>
  );
}
