import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../functions/config";

export default function Jobupdate() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [job_type, setJob_type] = useState("");
  const [description, setDescription] = useState("");

  const [department, setDepartment] = useState("");
  const [shift, setShift] = useState("");
  const [required_skills, setRequired_skills] = useState("");
  const [experience_required, setExperience_required] = useState("");
  const [other_department, setother_department] = useState("");

  const shiftOptions = ["Day Shift", "Night Shift"];

  // const experienceOptions = [
  //   { value: "", label: "Select Experience" },
  //   { value: "Freshers", label: "Freshers" },
  //   { value: "0", label: "0 Years" },
  //   { value: "1", label: "1 Year" },
  //   { value: "2", label: "2 Years" },
  // ];

  const experienceOptions = [
    { value: "", label: "Select Experience" },
    { value: "Freshers", label: "Freshers" },
    { value: "2", label: "1-2 Years" },
    { value: "4", label: "3-4 Years" },
    { value: "6", label: "5-6 Years" },
    { value: "8", label: "7-8 Years" },
    { value: "10", label: "9-10 Years" },
    { value: "Above10", label: "Above 10 Years" },
    
  ];

  const jobTypeOptions = [
    { value: "", label: "Select Job Type" },
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
    { value: "Temporary", label: "Temporary" },
  ];

  const departmentOptions = [
    // { value: "", label: "Select Department" },
    { value: "IT", label: "IT" },
    { value: "Banking", label: "Banking" },
    { value: "Accounting", label: "Accounting" },
    { value: "Nursing", label: "Nursing" },
    { value: "Others", label: "Others" },
  ];

  const navigate = useNavigate("");

  const { id } = useParams();

  useEffect(() => {
    async function fetchdata() {
      try {
        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/job/${id}`, {
        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        if (res1.data.status === 200) {
          console.log(res1);
          setTitle(res1.data.data.title);
          setCompany(res1.data.data.company);
          setSalary(res1.data.data.salary);
          setDescription(res1.data.data.description);
          // setLocation(res1.data.data.location);
          setAddress(res1.data.data.address);
          setCity(res1.data.data.city);
          setJob_type(res1.data.data.job_type);
          setDepartment(res1.data.data.department);
          setShift(res1.data.data.shift);
          setRequired_skills(res1.data.data.required_skills);
          setExperience_required(res1.data.data.experience_required);
          setother_department(res1.data.data.other_department);
        } else {
          console.log("error 1");
        }
      } catch (error) {}
    }
    fetchdata();
  }, [baseUrl, token]);

  // const handleSubmit = async (id) => {
  //   const data = {
  //     title: title,
  //     company: company,
  //     salary: salary,
  //     // location: location,
  //     address:address,
  //     city:city,
  //     description: description,
  //     job_type: job_type,

  //     department: department,
  //     shift: shift,
  //     required_skills: required_skills,
  //     experience_required: experience_required,
  //   };
  //   try {
  //     // const res2 = await axios.put(
  //     //   `${baseUrl}api/v1/ex1/job/update/${id}`,
  //     //   data,

  //     const res2 = await axios.put(
  //       `${config.baseUrl}api/v1/ex1/job/update/${id}`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //     if (res2.data.status === 200) {
  //       console.log(res2);
  //       toast.success("Job Updated Successfully", {
  //         autoclose: 1100,
  //         position: "top-right",
  //         onClose: () => {
  //           navigate("/employerd");
  //         },
  //       });
  //     } else {
  //       console.log("error 2");
  //       toast.error("Error", {
  //         autoclose: 1100,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error");
  //     toast.error("Error", {
  //       autoclose: 1100,
  //       position: "top-right",
  //     });
  //   }
  // };

  const handleSubmit = async (id) => {
    // Create an array to store missing or invalid fields
    const missingFields = [];
    const invalidFields = [];

    // Create regex for validation
    const textOnlyRegex = /^[A-Za-z\s]+$/; // For fields like title, company, and city
    const alphanumericWithTextRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For description, address, and required_skills
    const addressRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s,.-/]+$/; // For address to ensure it has text and numbers (e.g., "100 feet", "5th street")

    // Check for missing fields and add them to the missingFields array
    if (!title) missingFields.push("Title");
    if (!company) missingFields.push("Company");
    if (!salary) missingFields.push("Salary");
    if (!address) missingFields.push("Address");
    if (!city) missingFields.push("City");
    if (!description) missingFields.push("Description");
    if (!job_type) missingFields.push("Job Type");
    if (!department) missingFields.push("Department");
    if (!shift) missingFields.push("Shift");
    if (!required_skills) missingFields.push("Required Skills");
    if (!experience_required) missingFields.push("Experience Required");

     // Additional check for `other_department` if department is "Others"
  if (department === "Others" && (!other_department || !alphanumericWithTextRegex.test(other_department))) {
    invalidFields.push("Other Department (must contain text or alphanumeric characters)");
  }

    // Validate title and company (only letters and spaces)
    if (title && !alphanumericWithTextRegex.test(title)) {
      invalidFields.push(
        "Title (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }
    if (company && !alphanumericWithTextRegex.test(company)) {
      invalidFields.push(
        "Company (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }

    // Validate city (only letters and spaces)
    if (city && !textOnlyRegex.test(city)) {
      invalidFields.push("City (only letters and spaces allowed)");
    }

    // Validate description, address, and required_skills (must contain text or number with text and allow specific symbols)
    if (description && !alphanumericWithTextRegex.test(description)) {
      invalidFields.push(
        "Description (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }
    if (required_skills && !alphanumericWithTextRegex.test(required_skills)) {
      invalidFields.push(
        "Required Skills (must contain text, not just numbers, and can include spaces, commas, periods, hyphens, and slashes)"
      );
    }

    // Validate address (must contain at least one letter, numbers, and allow spaces, commas, periods, hyphens, slashes)
    if (address && !addressRegex.test(address)) {
      invalidFields.push(
        "Address (must be a valid address format with letters and numbers)"
      );
    }

    // If there are missing fields or invalid text fields, show a toast with the errors
    if (missingFields.length > 0 || invalidFields.length > 0) {
      let errorMessage = "";

      if (missingFields.length > 0) {
        errorMessage += `Please fill the following fields: ${missingFields.join(
          ", "
        )}. `;
      }

      if (invalidFields.length > 0) {
        errorMessage += `${invalidFields.join(", ")}.`;
      }

      toast.error(errorMessage, {
        autoClose: 5000,
        position: "top-right",
      });
      return; // Stop further execution if any required field is empty or invalid
    }

    const data = {
      title: title,
      company: company,
      salary: salary,
      address: address,
      city: city,
      description: description,
      job_type: job_type,
      department: department,
      other_department: other_department,
      shift: shift,
      required_skills: required_skills,
      experience_required: experience_required,
    };

    try {
      const res2 = await axios.put(
        `${config.baseUrl}api/v1/ex1/job/update/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (res2.data.status === 200) {
        console.log(res2);
        toast.success("Job Updated Successfully", {
          autoClose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/employerd");
          },
        });
      } else {
        console.log("error 2");
        toast.error("Error", {
          autoClose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoClose: 1100,
        position: "top-right",
      });
    }
  };

  return (
    <div className=" min-h-screen flex items-center justify-center bg-slate-100">
      <div className="container mx-auto p-4 sm:p-8 md:p-8 bg-white rounded-lg shadow-xl w-full max-w-4xl">
        <h2 className="text-2xl font-bold text-center text-violet-600 mb-2">
          Job Details
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* First Form (Job Details) */}
          <div>
            <form encType="multipart/form-data">
              {/* <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Job Details
          </h2> */}

              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-800"
                >
                  Title :
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-800"
                >
                  Company :
                </label>
                <input
                  type="text"
                  id="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-800"
                >
                  Description :
                </label>
                <input
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-800">
                  Job Type
                </label>
                <select
                  value={job_type}
                  onChange={(e) => setJob_type(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2"
                >
                  <option value="">{job_type}</option>
                  {jobTypeOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="salary"
                  className="block text-sm font-medium text-gray-800"
                >
                  Salary :
                </label>
                <input
                  type="number"
                  id="salary"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              {/* <div className="mb-6">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Location :
            </label>
            <input
              id="location"
              type="text"
              placeholder="Enter the location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
            />
          </div> */}

              <div className="">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address :
                </label>
                <input
                  id="address"
                  type="text"
                  placeholder="Enter the address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
                />
              </div>
            </form>
          </div>

          {/* Second Form (Additional Info) */}
          <div>
            <form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(id);
              }}
            >
              {/* <div className="mb-4">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Location :
            </label>
            <input
              id="location"
              type="text"
              placeholder="Enter the location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
            />
          </div> */}

              <div className="mb-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City :
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="Enter the city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
                />
              </div>

              {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-800">
                  Department
                </label>
                <select
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2"
                >
                  <option value="">{department}</option>
                  {departmentOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div> */}


<div className="mb-4">
  <label className="block text-sm font-medium text-gray-800">
    Department
  </label>
  <select
    value={department}
    onChange={(e) => setDepartment(e.target.value)}
    className="w-full border-2 border-gray-300 p-2 rounded mt-2"
  >
    <option value="">Select Department</option>
    {departmentOptions.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
</div>

{/* Conditionally render the 'Other Department' input */}
{department === "Others" && (
  <div className="mb-4">
    <label
      htmlFor="other_department"
      className="block text-sm font-medium text-gray-800"
    >
      Other Department:
    </label>
    <input
      type="text"
      id="other_department"
      value={other_department}
      onChange={(e) => setother_department(e.target.value)}
      className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    />
  </div>
)}





              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-800">
                  Shift
                </label>
                <select
                  value={shift}
                  onChange={(e) => setShift(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2"
                >
                  <option value="">{shift}</option>
                  {shiftOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-800">
                  Experience Required
                </label>
                <select
                  value={experience_required}
                  onChange={(e) => setExperience_required(e.target.value)}
                  className="w-full border-2 border-gray-300 p-2 rounded mt-2"
                >
                  <option value="">{experience_required}</option>
                  {experienceOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="required_skills"
                  className="block text-sm font-medium text-gray-800"
                >
                  Required Skills :
                </label>
                <input
                  type="text"
                  id="required_skills"
                  placeholder="Enter the required skills"
                  value={required_skills}
                  onChange={(e) => setRequired_skills(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                />
              </div>

              <button
                type="submit"
                className="mt-4 w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
              >
                Submit
              </button>

              {/* <Link
            to={`/job/${id}`}
            className="block text-center font-semibold text-violet-800 mt-4 hover:underline"
          >
            My Jobs
          </Link> */}
            </form>
          </div>
        </div>
        <div className="flex justify-center">
          <Link
            to={`/job/${id}`}
            className="block text-center font-semibold text-white w-14 px-3 py-1 rounded-xl mt-4 hover:underline bg-violet-600"
          >
            Back
          </Link>
        </div>
      </div>
    </div>
  );
}
