import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../functions/config";
import Notification from "./Notification";
import pro_pic from "../images/pro_pic.jpeg";

export default function Jobuserapplydetails() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [obj, setObj] = useState({});
  const [job, setJob] = useState([]);
  const [user, setUser] = useState([]);
  const [user1, setUser1] = useState([]);

  const [status, setStatus] = useState({});

  const { id } = useParams();

  const navigate = useNavigate();

  const [notificationCount, setNotificationCount] = useState(0);
  const [user2, setUser2] = useState([]);
  const [notification, setNotification] = useState([]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobapp/${id}`, {
        const response = await axios.get(
          `${config.baseUrl}api/v1/ex1/jobapp/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res2 = await axios.get(`${baseUrl}api/v1/ex1/user`, {
        const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/user`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        const res3 = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response);
          setObj(response.data.data);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
          setUser1(res1.data.user);
        } else {
          console.log("error 2");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setUser(res2.data.data);
        } else {
          console.log("error 2");
        }

        if (res3.data.status === 200) {
          console.log(res3);
          setNotification(res3.data.data);
          setUser2(res3.data.user_id);
          const count = res3.data.data.filter(
            (item) => item.user === res3.data.user_id && item.is_read === false
          );
          setNotificationCount(count.length);
        } else {
          console.log("error 5");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token, id]);
  return (
    <div>
      <div className="">
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4 fixed top-0 left-0 right-0">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>

          <div className="flex items-center gap-3">
            <div className="relative">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggleNotification();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                  />
                </svg>

                <span className="absolute top-0 right-0 left-4 block w-4 h-4 text-xs text-white bg-red-600 rounded-full text-center">
                  {notificationCount}
                </span>
              </button>
            </div>

            <h5 className="text-3xl font-bold text-white">Careerhub</h5>
          </div>

          {/* <h5 className="text-3xl font-bold text-white">Careerhub</h5> */}
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out fixed top-16 left-0 w-36 h-60 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/jobscopy">Jobs</Link>
            </li>
            <li>
              <Link to="/jobuserapply">Applied</Link>
            </li>
            {/* <li>
              <Link to="/jobuserselected">Jobs Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex bg-slate-200 min-h-screen">
        <nav>
          <div className=" hidden md:block sm:w-[200px] md:w-[280px] h-full bg-violet-600 fixed top-0 left-0 bottom-0">
            {/* <div className="hidden md:block w-40 md:w-48 lg:w-48 h-full bg-violet-600"> */}
            <div className="flex items-center p-5">
              {/* <img src={logo} width={50} height={50} /> */}
              {/* <h1 className="ml-3">GOOD JOBS</h1> */}
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={profile} width={30} height={30} /> */}

                {/* <a href="/userprofile" className="ml-5">
                  Profile
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>

              {/* <li className="flex items-center mt-7 px-8">
                
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                    />
                  </svg>
                  
  
                  <Link className="ml-5 font-bold text-white"><button onClick={(e)=>{e.preventDefault(); toggleNotification();}}>Notifications</button>
                    
                  </Link>
                </li> */}

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobscopy" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}

                {/* <a href="/jobuserapply" className="ml-5">
                  Jobs Applied
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobuserapply" className="ml-5 font-bold text-white">
                  Applied
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link
                  to="/jobuserselected"
                  className="ml-5 font-bold text-white"
                >
                  Jobs Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={logout} width={30} height={30} /> */}

                {/* <a href="/" className="ml-5">
                  Logout
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="flex-1 px-2 py-1 bg-slate-100 overflow-auto">
          <div className="hidden md:block w-full px-4 py-4">
            <div className="flex justify-end items-center gap-3">
              <div className="mr-3">
                <button
                  // onClick={() => navigate("/notification")}

                  onClick={(e) => {
                    e.preventDefault();
                    toggleNotification();
                  }}
                >
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 text-violet-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                      />
                    </svg>

                    <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
                      {notificationCount}
                    </span>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <img
                    // src={`${baseUrl}${user.image}`}
                    src={user1.image ? `${baseUrl}${user1.image}` : pro_pic}
                    // src={user.image ? user.image : pro_pic}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 md:ml-72 mt-20 md:mt-1 my-2 mx-2 shadow-lg rounded-md">
            <h3 className="text-xl text-violet-600 font-bold mb-3">
              Application Details
            </h3>
            <div className="space-y-4">
              <div>
                <h4 className="text-lg">
                  Title:{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.title}
                  </span>
                </h4>
              </div>

              <h4 className="text-lg">
                Company:{" "}
                <span className="font-semibold">
                  {" "}
                  {job.find((item) => item.id === obj.job)?.company}
                </span>
              </h4>
              <h4 className="text-base">
                Description:{" "}
                {job.find((item) => item.id === obj.job)?.description}
              </h4>
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                  />
                </svg>
                <h4 className="text-base">
                  Job Type:{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.job_type}
                  </span>
                </h4>
              </div>

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                  />
                </svg>

                <h4 className="text-base">
                  Address:{" "}
                  <span className="font-bold">
                    {" "}
                    {job.find((item) => item.id === obj.job)?.address}
                  </span>
                </h4>
              </div>

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                <h4 className="text-base">
                  Location:{" "}
                  <span className="font-bold">
                    {" "}
                    {job.find((item) => item.id === obj.job)?.city}
                  </span>
                </h4>
              </div>

              <div className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h4 className="text-base">
                  Salary:{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.salary}
                  </span>
                  {""} per month
                </h4>
              </div>

              <div className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                  />
                </svg>
                <h4 className="text-base">
                  Required_Skills :{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.required_skills}
                  </span>
                </h4>
              </div>

              <div className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
                <h4 className="text-base">
                  Shift :{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.shift}
                  </span>
                </h4>
              </div>

              <div className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                  />
                </svg>

                <h4 className="text-base">
                  Department :{" "}
                  <span className="font-bold">
                    {job.find((item) => item.id === obj.job)?.department}
                  </span>

                  {job.find((item) => item.id === obj.job)?.department === "Others" && job.find((item) => item.id === obj.job)?.other_department && (
                  <span className="text-sm text-gray-600 ml-2">({job.find((item) => item.id === obj.job)?.other_department})</span>
                )}

                </h4>
              </div>

              <div className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>

                <h4 className="text-base">
                  Status :{" "}
                  <span
                    className={`font-bold ${
                      obj.is_accepted === true
                        ? "text-green-500"
                        : obj.is_rejected === true
                        ? "text-red-500"
                        : "text-yellow-500"
                    }`}
                  >
                    {obj.is_accepted === true
                      ? "Accepted"
                      : obj.is_rejected === true
                      ? "Rejected"
                      : "Pending"}
                  </span>
                </h4>
              </div>

              <div className="flex items-center gap-1 mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>

                <h4 className="text-base">
                  Applied on :{" "}
                  <span className="font-bold">{obj.applied_on}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`hidden md:block fixed top-10 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>

      <div
        className={`md:hidden fixed top-11 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>
    </div>
  );
}
