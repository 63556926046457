// import { Link } from "react-router-dom";
// import {useNavigate} from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import config from "../functions/config";
// import pro_pic from "../images/pro_pic.jpeg";

// export default function Jobscopy() {
//   const baseUrl = localStorage.getItem("baseUrl");
//   const token = localStorage.getItem("token");

//   const [array, setArray] = useState([]);
//   const [user, setUser] = useState([]);
//   const navigate = useNavigate();
//   const [locationInput, setLocationInput] = useState("");
//   const [jobTypeInput, setJobTypeInput] = useState("");
//   const [titleInput, setTitleInput] = useState("");
//   const [obj, setObj] = useState({});
//   const [selectedJobId, setSelectedJobId] = useState(""); // State to track the selected job ID

//   const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

//   const [notificationCount, setNotificationCount] = useState(0);
//   const [user1,setUser1]=useState([])

//   const [notification, setNotification] = useState([]);

//   const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

//   const toggleNotification = () => {
//     setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
//   };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
//   };

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         // const response = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
//         const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Token ${token}`,
//           },
//         });

//         const res4 = await axios.get(`${config.baseUrl}api/v1/ex1/notifications`,{
//             headers: {
//               "Content-Type": "application/json",
//               "Authorization": `Token ${token}`,
//             },
//           }
//         );

//         if (response.data.status === 200) {
//           const jobs = response.data.data;
//           setArray(jobs);
//           setUser(response.data.user);

//           // Filter out jobs that are already applied to (is_allowed === true)
//           const availableJobs = jobs.filter((job) => job.is_allowed === false);

//           if (availableJobs.length > 0) {
//             // Set the first available job as the default selected job
//             setSelectedJobId(availableJobs[0].id);
//             handleClick1(availableJobs[0].id); // Load the first available job details
//           }
//         } else {
//           console.log("Error fetching job data.");
//         }

//         if(res4.data.status===200){
//           console.log(res4)
//           setNotification(response.data.data);
//           setUser1(res4.data.user_id);
//           const count = res4.data.data.filter((item) => item.user === res4.data.user_id && item.is_read === false);
//           setNotificationCount(count.length);

//         }
//         else{
//           console.log("error 5")
//         }

//       } catch (error) {
//         console.log("Error fetching data");
//       }
//     }

//     fetchData();
//   }, [baseUrl, token, locationInput, jobTypeInput, titleInput,]);

//   const handleClick = async (id) => {
//     const data = {
//       is_allowed: true,
//     };
//     try {
//       // const res1 = await axios.put(
//       //   `${baseUrl}api/v1/ex1/job/allow/${id}`,
//       //   data,

//       const res1 = await axios.put(
//         `${config.baseUrl}api/v1/ex1/job/allow/${id}`,
//         data,

//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Token ${token}`,
//           },
//         }
//       );
//       if (res1.data.status === 200) {
//         console.log(res1);
//         navigate(`/apply/${id}`);
//       } else {
//         console.log("Error in applying for the job.");
//       }
//     } catch (error) {
//       console.log("Error during application.");
//     }
//   };

//   const handleClick1 = async (id) => {
//     try {
//       // const res2 = await axios.get(`${baseUrl}api/v1/ex1/job/${id}`, {
//       const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Token ${token}`,
//         },
//       });
//       if (res2.data.status === 200) {
//         console.log(res2);
//         setObj(res2.data.data);
//       } else {
//         console.log("Error fetching job details.");
//       }
//     } catch (error) {
//       console.log("Error while fetching job details.");
//     }
//   };

//   const handleClick2 = async () => {
//     try {
//       // const res3 = await axios.get(`${baseUrl}api/v1/ex1/profile`, {
//       const res3 = await axios.get(`${config.baseUrl}api/v1/ex1/profile`, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Token ${token}`,
//         },
//       });
//       if (res3.data.status === 200) {
//         console.log(res3);
//         navigate("/userprofile");
//       } else {
//         console.log("Error fetching profile.");
//       }
//     } catch (error) {
//       console.log("Error fetching profile details.");
//     }
//   };

//   const handleJobSearch = async () => {
//     try {
//       // const response = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
//       const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Token ${token}`,
//         },
//         params: {
//           location: locationInput,
//           job_type: jobTypeInput,
//           title: titleInput,
//         },
//       });

//       if (response.data.status === 200) {
//         const jobs = response.data.data;
//         setArray(jobs);
//         setUser(response.data.user);

//         // Filter out jobs that are already applied to (is_allowed === true)
//         const availableJobs = jobs.filter((job) => job.is_allowed === false);

//         if (availableJobs.length > 0) {
//           // Set the first available job as the default selected job
//           setSelectedJobId(availableJobs[0].id);
//           handleClick1(availableJobs[0].id); // Load the first available job details
//         }
//       } else {
//         console.log("Error fetching job data.");
//       }
//     } catch (error) {
//       console.log("Error fetching data");
//     }
//   };

//   const handleSearchClick = (e) => {
//     e.preventDefault();
//     handleJobSearch(); // Trigger the job search API call
//   };

//   return (
//     <div>
//       <div className="w-full px-3 py-6 flex justify-between items-center bg-violet-600 rounded">
//         <div className="flex items-center ">
//           <button
//             onClick={(e) => {
//               e.preventDefault();
//               toggleMenu();
//             }}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke-width="1.5"
//               stroke="currentColor"
//               class="size-7 text-white"
//             >
//               <path
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
//               />
//             </svg>
//           </button>
//         </div>

//         <div>
//           <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
//         </div>

//         {/* <div className="hidden md:flex items-center ">
//           <input
//             type="text"
//             placeholder="Search jobs"
//             className="bg-white border-none rounded-lg w-full p-2 border border-violet-600 "
//           />
//           <button className=" bg-violet-600 font-bold text-white rounded-xl p-3 ml-5">
//             Search
//           </button>
//         </div> */}

//         <div className="hidden md:flex items-center gap-3">
//           <input
//             type="text"
//             placeholder="Search By Title"
//             className="rounded-xl w-full py-1"
//             value={titleInput}
//             onChange={(e) => setTitleInput(e.target.value)}
//           />

//           {/* <input
//             type="text"
//             placeholder="Search By Job Type"
//             className="border border-r-black  p-2 w-32"
//             value={jobTypeInput}
//             onChange={(e) => setJobTypeInput(e.target.value)}
//           /> */}

//           <select
//             className=" rounded-xl w-full px-1 py-1"
//             value={locationInput}
//             onChange={(e) => setLocationInput(e.target.value)}
//           >
//             <option value="" disabled hidden selected>
//               Location
//             </option>
//             <option value="Chennai">Chennai</option>
//             <option value="Bangalore">Bangalore</option>
//             <option value="Coimbatore">Coimbatore</option>
//           </select>

//           <button
//             onClick={handleSearchClick}
//             className="bg-white text-violet-600 font-extrabold px-2 w-24 rounded-2xl ml-3"
//           >
//             Search
//           </button>
//         </div>

//         <div className="flex items-center gap-3 ">
//           <div className="mr-3">
//             <button
//             onClick={() => navigate("/notification")}

//             // onClick={(e) => {
//             //   e.preventDefault();
//             //   toggleNotification();
//             // }}

//             >
//               <div className="relative">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke-width="1.5"
//                 stroke="currentColor"
//                 className="size-7 text-white"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
//                 />
//               </svg>

//               <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
//         {notificationCount}
//       </span>
//       </div>
//               {/* <h3>{notificationCount}</h3> */}
//             </button>
//           </div>

//           <div>
//             <button onClick={handleClick2}>
//               <img
//                 // src={`${baseUrl}${user.image}`}
//                 src={user.image ? `${baseUrl}${user.image}` : pro_pic}
//                 alt="User Avatar"
//                 className="w-10 h-10 rounded-full object-cover"
//               />
//             </button>
//           </div>
//           {/* <button onClick={handleClick2}>
//             <img
//               // src={`${baseUrl}${user.image}`}
//               src={user.image ? `${baseUrl}${user.image}` : pro_pic}
//               alt="User Avatar"
//               className="w-10 h-10 rounded-full object-cover"
//             />
//           </button> */}

//           {/* <Link
//             to="/userprofile"
//             className="block text-white font-bold md:pr-5"
//           >
//             {user.first_name} {user.last_name}
//           </Link> */}
//         </div>
//       </div>
//       {/* <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}> */}
//       {/* <div className={`lg:hidden ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
//           <ul className=" bg-slate-300">
//             <li><Link to="">Profile</Link></li>
//             <li><Link to="">Jobs</Link></li>
//             <li><Link to="">Jobs Applied</Link></li>
//             <li><Link to="">Jobs Selected</Link></li>
//             <li><Link to="">Logout</Link></li>
//           </ul>
//         </div> */}

//       <div
//         className={`lg:hidden ${
//           isMenuOpen ? "translate-x-0" : "-translate-x-full"
//         } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-60 bg-violet-600`}
//       >
//         <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
//           <li>
//             <Link to="/userprofile">Profile</Link>
//           </li>
//           <li>
//             <Link to="/jobscopy">Jobs</Link>
//           </li>
//           <li>
//             <Link to="/jobuserapply">Applied</Link>
//           </li>
//           {/* <li>
//             <Link to="/jobuserselected">Jobs Selected</Link>
//           </li> */}
//           <li>
//             <Link to="/">Logout</Link>
//           </li>
//         </ul>
//       </div>

//       <div
//         className={`hidden lg:block ${
//           isMenuOpen ? "translate-x-0" : "-translate-x-full"
//         } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-60 h-80 font-bold bg-violet-600`}
//       >
//         <ul>
//           <li className="flex items-center mt-7 px-8">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke-width="1.5"
//               stroke="currentColor"
//               className="size-8 text-white"
//             >
//               <path
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
//               />
//             </svg>

//             <Link to="/userprofile" className="ml-5 font-bold text-white">
//               Profile
//             </Link>
//           </li>

//           <li className="flex items-center mt-7 px-8">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke-width="1.5"
//               stroke="currentColor"
//               className="size-7 text-white"
//             >
//               <path
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
//               />
//             </svg>

//             <Link to="/jobscopy" className="ml-5 font-bold text-white">
//               Jobs
//             </Link>
//           </li>
//           <li className="flex items-center mt-7 px-8">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke-width="1.5"
//               stroke="currentColor"
//               className="size-6 text-white"
//             >
//               <path
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
//               />
//             </svg>

//             <Link to="/jobuserapply" className="ml-5 font-bold text-white">
//               Applied
//             </Link>
//           </li>
//           {/* <li className="flex items-center mt-7 px-8">

//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   className="size-7 text-white"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
//                   />
//                 </svg>

//                 <Link
//                   to="/jobuserselected"
//                   className="ml-5 font-bold text-white"
//                 >
//                   Jobs Selected
//                 </Link>
//               </li> */}
//           <li className="flex items-center mt-7 px-8">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke-width="1.5"
//               stroke="currentColor"
//               className="size-7 text-white"
//             >
//               <path
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
//               />
//             </svg>

//             <Link to="/" className="ml-5 font-bold text-white">
//               Logout
//             </Link>
//           </li>
//         </ul>
//       </div>

//       <div className="py-5">
//         <div className="flex flex-wrap justify-center items-center md:hidden px-10 gap-3">
//           {/* <input
//             type="text"
//             placeholder="Search jobs"
//             className="bg-white rounded-lg w-full p-2 border border-violet-600"
//           />
//           <button className=" bg-violet-600 font-bold text-white rounded-xl p-1 ml-5">
//             Search
//           </button> */}

//           <input
//             type="text"
//             placeholder="Title"
//             className=" rounded-lg w-full border border-gray-600"
//             value={titleInput}
//             onChange={(e) => setTitleInput(e.target.value)}
//           />

//           {/* <input
//             type="text"
//             placeholder="Search Job Type"
//             className="border border-r-black  p-2 w-32"
//             value={jobTypeInput}
//             onChange={(e) => setJobTypeInput(e.target.value)}
//           /> */}

//           <select
//             className=" w-full rounded-lg border border-gray-600"
//             value={locationInput}
//             onChange={(e) => setLocationInput(e.target.value)}
//           >
//             <option value="" disabled hidden selected>
//               Location
//             </option>
//             <option value="Chennai">Chennai</option>
//             <option value="Bangalore">Bangalore</option>
//             <option value="Coimbatore">Coimbatore</option>
//           </select>

//           <button
//             onClick={handleSearchClick}
//             className="bg-violet-600 text-white font-bold w-24 rounded-2xl py-1 ml-1"
//           >
//             Search
//           </button>
//         </div>
//       </div>

//       <div className="my-4">
//         <div className="bg-white rounded-lg">
//           <h1 className="text-violet-600 font-bold text-center text-xl mb-2">
//             Jobs
//           </h1>
//           <div className="flex mx-auto max-w-screen-lg">
//             <div className="w-full md:w-1/2 h-screen overflow-auto">
//               <ul className="flex flex-wrap gap-6 p-4">
//                 {array
//                   .filter((item) => item.is_allowed === false)
//                   .map((item) => (
//                     <li
//                       key={item.id}
//                       className="bg-white border border-violet-800 rounded-lg shadow-2xl p-6 w-full h-full"
//                     >
//                       <div>
//                         <div
//                           onClick={() => {
//                             setSelectedJobId(item.id); // Set selected job ID on click
//                             handleClick1(item.id); // Fetch the job details
//                           }}
//                         >
//                           <h3 className="text-xl font-semibold text-black mb-1">
//                             {item.title}
//                           </h3>
//                           <h3 className="text-gray-500 font-medium mb-1">
//                             {item.company}
//                           </h3>

//                           <div className="flex flex-wrap gap-4">
//                             <div className="flex items-center gap-1 bg-slate-100 px-2 py-1 rounded">
//                               <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 fill="none"
//                                 viewBox="0 0 24 24"
//                                 stroke-width="1.5"
//                                 stroke="currentColor"
//                                 className="size-5 text-gray-500"
//                               >
//                                 <path
//                                   stroke-linecap="round"
//                                   stroke-linejoin="round"
//                                   d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
//                                 />
//                               </svg>
//                               <h3 className=" text-sm font-semibold text-gray-500 mb-1">
//                                 {item.salary} per month
//                               </h3>
//                             </div>

//                             <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
//                               <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 fill="none"
//                                 viewBox="0 0 24 24"
//                                 stroke-width="1.5"
//                                 stroke="currentColor"
//                                 className="size-5 text-gray-500"
//                               >
//                                 <path
//                                   stroke-linecap="round"
//                                   stroke-linejoin="round"
//                                   d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
//                                 />
//                               </svg>
//                               <h3 className="text-sm font-semibold text-gray-500 mb-1">
//                                 {item.job_type}
//                               </h3>
//                             </div>

//                             <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
//                               <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 fill="none"
//                                 viewBox="0 0 24 24"
//                                 stroke-width="1.5"
//                                 stroke="currentColor"
//                                 className="size-5 text-gray-500"
//                               >
//                                 <path
//                                   stroke-linecap="round"
//                                   stroke-linejoin="round"
//                                   d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
//                                 />
//                                 <path
//                                   stroke-linecap="round"
//                                   stroke-linejoin="round"
//                                   d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
//                                 />
//                               </svg>
//                               <h3 className="text-sm font-semibold text-gray-500 mb-1">
//                                 {item.location}
//                               </h3>
//                             </div>
//                             <div>
//                               <h4 className=" font-sm  text-gray-500 h-6 overflow-auto">
//                                 Required Skills: {item.required_skills}
//                               </h4>
//                             </div>

//                             <h4 className=" font-sm  text-gray-700">
//                               Posted on: {item.posted_on}
//                             </h4>

//                             <div className="mx-auto  md:hidden ">
//                               <button className="bg-violet-600 text-white font-semibold rounded-2xl px-2 py-1 mt-1">
//                                 <Link to={`/jobsuserdetails/${item.id}`}>
//                                   View Details
//                                 </Link>
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   ))}
//               </ul>
//             </div>

//             <div className="hidden md:block w-full md:w-1/2 sticky top-0 bg-white shadow-xl rounded mx-auto h-screen overflow-auto">
//               {selectedJobId ? (
//                 <div className="ml-3">
//                   <h2 className="text-2xl font-bold text-gray-600 mb-2">
//                     {obj.title}
//                   </h2>
//                   <h2 className="text-lg font-medium text-gray-600 mb-2">
//                     {obj.company}
//                   </h2>
//                   <h3 className="text-lg text-gray-600 mb-2">
//                     {obj.description}
//                   </h3>
//                   <div className="flex items-center gap-2 mb-2">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-6"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
//                       />
//                     </svg>

//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Salary : {obj.salary} per month
//                     </h4>
//                   </div>

//                   <div className="flex items-center gap-2 mb-2">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-5"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
//                       />
//                     </svg>

//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Job Type: {obj.job_type}
//                     </h4>
//                   </div>

//                   <div className="flex items-center gap-2 mb-2">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-5"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
//                       />
//                     </svg>
//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Shift: {obj.shift}
//                     </h4>
//                   </div>

//                   <div className="flex items-center gap-2 mb-2">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-6"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
//                       />
//                     </svg>
//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Department: {obj.department}
//                     </h4>
//                   </div>

//                   <div className="flex gap-2 mb-2">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-8"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
//                       />
//                     </svg>
//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Required Skills: {obj.required_skills}
//                     </h4>
//                   </div>

//                   <div className="flex items-center gap-2 mb-1">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-6"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
//                       />
//                     </svg>
//                     <h4 className="text-lg font-semibold text-gray-700">
//                       Experience: {obj.experience_required}
//                     </h4>
//                   </div>

//                   <div className="flex items-center gap-2 mb-1">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke-width="1.5"
//                       stroke="currentColor"
//                       className="size-6"
//                     >
//                       <path
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
//                       />
//                     </svg>

//                     <h4 className=" font-semibold text-gray-800">
//                       Posted on: {obj.posted_on}
//                     </h4>
//                   </div>

//                   <button
//                     className="mt-4 bg-violet-600 text-white font-bold rounded-3xl w-20 px-4 py-1"
//                     onClick={() => handleClick(selectedJobId)}
//                   >
//                     Apply
//                   </button>
//                 </div>
//               ) : (
//                 <p>Select a job to view details</p>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         className={`${
//           isNotificationOpen ? "-translate-x-0" : "translate-x-full"
//         } transition-transform duration-300 ease-in-out absolute top-20 right-0 w-96 h-full bg-violet-600`}
//        >
//         <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
//           <li>
//             <Link to="/userprofile">Profile</Link>
//           </li>
//           <li>
//             <Link to="/jobscopy">Jobs</Link>
//           </li>
//           <li>
//             <Link to="/jobuserapply">Applied</Link>
//           </li>
//           {/* <li>
//             <Link to="/jobuserselected">Jobs Selected</Link>
//           </li> */}
//           <li>
//             <Link to="/">Logout</Link>
//           </li>
//         </ul>
//       </div>

//     </div>
//   );
// }

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../functions/config";
import pro_pic from "../images/pro_pic.jpeg";

import Notification from "../components/Notification";

export default function Jobscopy() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [job, setJob] = useState([]);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  const [locations, setLocations] = useState([]);

  const [cityInput, setCityInput] = useState("");

  const [locationInput, setLocationInput] = useState("");
  const [jobTypeInput, setJobTypeInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [obj, setObj] = useState({});

  const [selectedJob, setSelectedJob] = useState("");
  const [search, setSearch] = useState(0);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const [notificationCount, setNotificationCount] = useState(0);
  const [user1, setUser1] = useState([]);
  const [user2, setUser2] = useState([]);

  const [notification, setNotification] = useState([]);
  const [jobapplication, setJobapplication] = useState([]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const uniqueCities = [...new Set(locations.map((item) => item.city))];

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          params: {
            city: cityInput,
            job_type: jobTypeInput,
            title: titleInput,
          },
        });

        const res4 = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const res5 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        const res6 = await axios.get(`${config.baseUrl}api/v1/ex1/locations`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (response.data.status === 200) {
          setJob(response.data.data);
          setUser(response.data.user);

          // Filter jobs based on the job application condition
          // const filteredJobs = job.filter(
          //   (item) =>
          //     !jobapplication.find(
          //       (itm) => itm.job === item.id && itm.user === user2
          //     )
          // );

          const filteredJobs = response.data.data.filter(
            (item) =>
              !res5.data.data.find(
                (itm) => itm.job === item.id && itm.user === res5.data.user_id
              )
          );

          // Initialize the first job's details from the filtered list when the component mounts

          if (filteredJobs.length > 0) {
            // setSelectedJob(filteredJobs[0].id);

            setSelectedJob(filteredJobs[0]);

            handleClick1(filteredJobs[0].id); // Default to first job in filtered list
          }
        } else {
          console.log("Error fetching job data.");
        }

        if (res4.data.status === 200) {
          console.log(res4);
          setNotification(res4.data.data);
          setUser1(res4.data.user_id);
          const count = res4.data.data.filter(
            (item) => item.user === res4.data.user_id && item.is_read === false
          );
          setNotificationCount(count.length);
        } else {
          console.log("error 5");
        }

        if (res5.data.status === 200) {
          console.log(res5);
          setJobapplication(res5.data.data);
          setUser2(res5.data.user_id);
        } else {
          console.log("error 6");
        }

        if (res6.data.status === 200) {
          console.log(res6);
          setLocations(res6.data.data);
        } else {
          console.log("error 7");
        }
      } catch (error) {
        console.log("Error fetching data");
      }
    }

    fetchData();
  }, [baseUrl, token, search]);

  const handleClick = (id) => {
    navigate(`/apply/${id}`);
  };

  const handleClick1 = async (id) => {
    try {
      const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      if (res2.data.status === 200) {
        console.log(res2);
        // setObj(res2.data.data);
        setSelectedJob(res2.data.data);
      } else {
        console.log("Error fetching job details.");
      }
    } catch (error) {
      console.log("Error while fetching job details.");
    }
  };

  const handleClick2 = async () => {
    try {
      const res3 = await axios.get(`${config.baseUrl}api/v1/ex1/profile`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (res3.data.status === 200) {
        console.log(res3);
        navigate("/userprofile");
      } else {
        console.log("Error fetching profile.");
      }
    } catch (error) {
      console.log("Error fetching profile details.");
    }
  };

  // const handleJobSearch = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //       params: {
  //         // location: locationInput,
  //         city: cityInput,
  //         job_type: jobTypeInput,
  //         title: titleInput,
  //       },
  //     });

  //     if (response.data.status === 200) {
  //       setJob(response.data.data);
  //       setUser(response.data.user);

  //       const filteredJobs = job.filter(
  //         (item) =>
  //           !jobapplication.find(
  //             (itm) => itm.job === item.id && itm.user === user2
  //           )
  //       );

  //       // Initialize the first job's details from the filtered list when the component mounts

  //       if (filteredJobs.length > 0) {
  //         // setSelectedJob(filteredJobs[0].id);
  //         setSelectedJob(filteredJobs[0]);
  //         handleClick1(filteredJobs[0].id); // Default to first job in filtered list
  //       }
  //     } else {
  //       console.log("Error fetching job data.");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching data");
  //   }
  // };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(search + 1);
    // handleJobSearch(); // Trigger the job search API call
  };

  return (
    <div className="relative">
      <div className="fixed top-0 z-10 w-full px-3 py-6 flex justify-between items-center bg-violet-600 rounded">
        <div className="flex items-center relative">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-7 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>       
        </div>

        <div
        className={`lg:hidden ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out absolute top-[90px] left-0 w-36 h-60 bg-violet-600`}
       >
        <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
          <li>
            <Link to="/userprofile">Profile</Link>
          </li>
          <li>
            <Link><button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/jobscopy";
                }}
              >
                Jobs
              </button></Link>
          </li>
          <li>
            <Link to="/jobuserapply">Applied</Link>
          </li>
          <li>
            <Link to="/">Logout</Link>
          </li>
        </ul>
      </div>

        <div>
          <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
        </div>

        <div className="hidden md:flex items-center gap-3">
          <input
            type="text"
            placeholder="Search By Title"
            className="rounded-xl w-full py-1 text-center text-base"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
          />

          <select
            className=" rounded-xl w-full px-1 py-1"
            value={cityInput}
            onChange={(e) => setCityInput(e.target.value)}
          >
            <option value="" disabled hidden selected>
              Location
            </option>
            {/* {locations.map((item) => (
          <option key={item.id} value={item.city}>
            {item.city}
          </option>
            ))} */}

            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>

          <button
            onClick={handleSearchClick}
            className="bg-white text-violet-600 font-medium px-2 w-24 rounded-2xl ml-3"
          >
            Search
          </button>
        </div>

        <div className="flex items-center gap-3 ">
          <div className="mr-3">
            <button
              // onClick={() => navigate("/notification")}

              onClick={(e) => {
                e.preventDefault();
                toggleNotification();
              }}
            >
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                  />
                </svg>

                <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
                  {notificationCount}
                </span>
              </div>
            </button>
          </div>

          <div>
            <button onClick={handleClick2}>
              <img
                // src={`${baseUrl}${user.image}`}
                src={user.image ? `${baseUrl}${user.image}` : pro_pic}
                alt="User Avatar"
                className="w-10 h-10 rounded-full object-cover"
              />
            </button>
          </div>
        </div>

        


      </div>

      {/* <div
        className={`lg:hidden ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-60 bg-violet-600`}
       >
        <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
          <li>
            <Link to="/userprofile">Profile</Link>
          </li>
          <li>
            <Link><button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/jobscopy";
                }}
              >
                Jobs
              </button></Link>
          </li>
          <li>
            <Link to="/jobuserapply">Applied</Link>
          </li>
          <li>
            <Link to="/">Logout</Link>
          </li>
        </ul>
      </div> */}

      <div
        className={`hidden lg:block ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out fixed top-24 left-0 w-60 h-80 font-bold bg-violet-600 z-10`}
       >
        <ul>
          <li className="flex items-center mt-7 px-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-8 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>

            <Link to="/userprofile" className="ml-5 font-bold text-white">
              Profile
            </Link>
          </li>

          <li className="flex items-center mt-7 px-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-7 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
              />
            </svg>

            
            <Link className="ml-5 font-bold text-white">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/jobscopy";
                }}
              >
                Jobs
              </button>
            </Link>
          </li>
          <li className="flex items-center mt-7 px-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
              />
            </svg>

            <Link to="/jobuserapply" className="ml-5 font-bold text-white">
              Applied
            </Link>
          </li>

          <li className="flex items-center mt-7 px-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-7 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
              />
            </svg>

            <Link to="/" className="ml-5 font-bold text-white">
              Logout
            </Link>
          </li>
        </ul>
      </div>

      <div className="">
        <div className=" flex flex-wrap justify-center items-center md:hidden px-10 mt-36 gap-3">
          <input
            type="text"
            placeholder="Search by Title"
            className=" rounded-lg w-full border border-gray-600 text-base text-center mb-2 p-1"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
          />

          {/* <select
            className=" w-full rounded-lg border border-gray-600"
            value={locationInput}
            onChange={(e) => setLocationInput(e.target.value)}
          >
            <option value="" disabled hidden selected>
              Location
            </option>
            <option value="Chennai">Chennai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Coimbatore">Coimbatore</option>
            
          </select> */}

          <select
            className=" w-full rounded-lg border border-gray-600 p-1 mb-2"
            value={cityInput}
            onChange={(e) => setCityInput(e.target.value)}
          >
            <option value="" disabled hidden selected>
              Location
            </option>
            {/* {locations.map((item) => (
          <option key={item.id} value={item.city}>
            {item.city}
          </option>
            ))} */}

            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>

          <button
            onClick={handleSearchClick}
            className="bg-violet-600 text-white font-bold w-24 rounded-2xl py-1 ml-1"
          >
            Search
          </button>
        </div>
      </div>

      <div className="my-4 relative">
        <div className="md:absolute top-24 left-0 right-0 bg-white rounded-lg">
          <h1 className="text-violet-600 font-bold text-center text-xl mb-2">
            Jobs
          </h1>
          <div className="flex mx-auto max-w-screen-lg">
            <div className="w-full md:w-1/2 h-screen overflow-auto">
              {job.filter(
                (item) =>
                  !jobapplication.find(
                    (itm) => itm.job === item.id && itm.user === user2
                  )
              ).length === 0 ? (
                <div className="text-center w-full py-4">
                  <h3 className="text-xl font-semibold text-gray-500">
                    No jobs available
                  </h3>
                </div>
              ) : (
                <ul className="flex flex-wrap gap-6 p-4">
                  {job
                    .filter(
                      (item) =>
                        !jobapplication.find(
                          (itm) => itm.job === item.id && itm.user === user2
                        )
                    )

                    .map((item) => (
                      <li
                        key={item.id}
                        className="bg-white border border-violet-800 rounded-lg shadow-2xl p-6 w-full h-full"
                      >
                        <div>
                          <div
                            onClick={() => {
                              setSelectedJob(item.id); // Set selected job ID on click
                              handleClick1(item.id); // Fetch the job details
                            }}
                          >
                            <h3 className="text-xl font-semibold text-black mb-1">
                              {item.title}
                            </h3>
                            <h3 className="text-gray-500 font-medium mb-1">
                              {item.company}
                            </h3>

                            <div className="flex flex-wrap gap-4">
                              <div className="flex items-center gap-1 bg-slate-100 px-2 py-1 rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                                <h3 className=" text-sm font-semibold text-gray-500 mb-1">
                                  {item.salary} per month
                                </h3>
                              </div>

                              <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                                  />
                                </svg>
                                <h3 className="text-sm font-semibold text-gray-500 mb-1">
                                  {item.job_type}
                                </h3>
                              </div>

                              <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                  />
                                </svg>
                                {/* <h3 className="text-sm font-semibold text-gray-500 mb-1">
                                {item.location}
                              </h3> */}
                                <h3 className="text-sm font-semibold text-gray-500 mb-1">
                                  {item.city}
                                </h3>
                              </div>
                              <div>
                                <h4 className=" font-sm  text-gray-500 h-6 overflow-auto">
                                  Required Skills: {item.required_skills}
                                </h4>
                              </div>

                              <h4 className=" font-sm  text-gray-700">
                                Posted on: {item.posted_on}
                              </h4>

                              <div className="mx-auto  md:hidden ">
                                <button className="bg-violet-600 text-white font-semibold rounded-2xl px-2 py-1 mt-1">
                                  <Link to={`/jobsuserdetails/${item.id}`}>
                                    View Details
                                  </Link>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="hidden md:block w-full md:w-1/2 sticky top-0 bg-white shadow-xl rounded mx-auto h-screen overflow-auto">
              
            {job.filter(
      (item) =>
        !jobapplication.find(
          (itm) => itm.job === item.id && itm.user === user2
        )
    ).length === 0 ? (
      <div className="text-center w-full py-4">
        <h3 className="text-xl font-semibold text-gray-500">
          No jobs available to view
        </h3>
      </div>
    ) : 
              
              selectedJob ? (
                <div className="ml-3 py-5">
                  <h2 className="text-2xl font-bold text-gray-600 mb-2">
                    {selectedJob.title}
                  </h2>
                  <h2 className="text-lg font-medium text-gray-600 mb-2">
                    {selectedJob.company}
                  </h2>
                  <h3 className="text-base text-gray-600 mb-2">
                    {selectedJob.description}
                  </h3>
                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>

                    <h4 className="text-base font-medium text-gray-700">
                      Salary : {selectedJob.salary} per month
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                      />
                    </svg>

                    <h4 className="text-base font-medium text-gray-700">
                      Job Type: {selectedJob.job_type}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                      />
                    </svg>
                    <h4 className="text-base font-medium text-gray-700">
                      Shift: {selectedJob.shift}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                      />
                    </svg>
                    <h4 className="text-base font-medium text-gray-700">
                      Department: {selectedJob.department}

                      {selectedJob.department === "Others" && selectedJob.other_department && (
                  <span className="text-sm text-gray-600 ml-2">({selectedJob.other_department})</span>
                )}


                    </h4>
                  </div>

                  <div className="flex gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                      />
                    </svg>
                    <h4 className="text-base font-medium text-gray-700">
                      Required Skills: {selectedJob.required_skills}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                      />
                    </svg>
                    <h4 className="text-base font-semibold text-gray-700">
                      Experience: {selectedJob.experience_required}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>

                    <h4 className=" font-medium text-gray-800">
                      Posted on: {selectedJob.posted_on}
                    </h4>
                  </div>

                  <button
                    className="mt-4 bg-violet-600 text-white font-bold rounded-3xl w-20 px-4 py-1"
                    onClick={() => handleClick(selectedJob.id)}
                  >
                    Apply
                  </button>
                </div>
              ) : (
                <p>Select a job to view details</p>
              )}
            </div>
          </div>
        </div>
      </div>

     

      <div
        className={` fixed top-20 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
      >
        <Notification />
      </div>

      
    </div>
  );
}
