import { Link } from "react-router-dom";
import React, { useState } from "react";
import signup from "../images/signup.jpeg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../functions/config";
import pro_pic from "../images/pro_pic.jpeg";

export default function Signup() {
  const [username, setUsername] = useState(""); // email
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [user_type, setUser_type] = useState("");
  const [address, setAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(""); // To hold phone number error message
  const [emailError, setEmailError] = useState(""); // To hold email error message
  const [fieldErrors, setFieldErrors] = useState({}); // To hold any field errors

  const navigate = useNavigate();
  const baseUrl = localStorage.getItem("baseUrl");

  // Phone number validation function
  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Reset error messages
    setPhoneError("");
    setEmailError("");
    setFieldErrors({});

    let errors = {};

    // Check if required fields are empty and set error messages
    if (!username) errors.username = "Email is required.";
    if (!first_name) errors.first_name = "First name is required.";
    if (!last_name) errors.last_name = "Last name is required.";
    if (!phone) errors.phone = "Phone number is required.";
    if (!user_type) errors.user_type = "User type is required.";
    if (!address) errors.address = "Address is required.";

    // If there are errors, set them and stop the submission
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setLoading(false);
      return;
    }

    // Validate email
    if (!validateEmail(username)) {
      setEmailError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Validate phone number
    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be exactly 10 digits.");
      setLoading(false);
      return;
    }

    const data = {
      email: username,
      first_name: first_name,
      last_name: last_name,
      image: image,
      phone: phone,
      user_type: user_type,
      address: address,
    };

    try {
      const response = await axios.post(
        `${config.baseUrl}api/v1/auth/signup`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === 200) {
        setLoading(false);
        toast.success("Successfully registered!", {
          autoClose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/");
          },
        });
      } else if (
        response.data.status === 201 &&
        response.data.message === "Email already exists"
      ) {
        setEmailError("Email is already registered.");
        setLoading(false);
        toast.error("Email already registered.");
      } else {
        setLoading(false);
        toast.error("An error occurred, please try again.");
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      toast.error("Error during registration.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-slate-200">
      <div className="w-full max-w-4xl p-6 bg-white rounded-xl shadow-xl md:mx-5">
        <h2 className="font-bold text-center text-violet-600 text-3xl mb-6">
          Signup
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <form className="flex flex-col" encType="multipart/form-data">
              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="email"
              >
                Email:
              </label>
              <input
                className="p-2 w-full rounded-xl border border-violet-500 focus:outline-none"
                id="email"
                type="text"
                placeholder="Enter Email"
                value={username}
                onChange={(e) => {
                  e.preventDefault();
                  setUsername(e.target.value);
                }}
              />
              {emailError && (
                <span className="text-red-500 text-xs">{emailError}</span>
              )}
              {fieldErrors.username && (
                <span className="text-red-500 text-xs">{fieldErrors.username}</span>
              )}

              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="first_name"
              >
                First Name:
              </label>
              <input
                className="p-2 rounded-xl border border-violet-500 focus:outline-none"
                id="first_name"
                type="text"
                placeholder="Enter First Name"
                value={first_name}
                onChange={(e) => {
                  e.preventDefault();
                  setFirst_name(e.target.value);
                }}
              />
              {fieldErrors.first_name && (
                <span className="text-red-500 text-xs">{fieldErrors.first_name}</span>
              )}

              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="last_name"
              >
                Last Name:
              </label>
              <input
                className="p-2 rounded-xl border border-violet-500 focus:outline-none"
                id="last_name"
                type="text"
                placeholder="Enter Last Name"
                value={last_name}
                onChange={(e) => {
                  e.preventDefault();
                  setLast_name(e.target.value);
                }}
              />
              {fieldErrors.last_name && (
                <span className="text-red-500 text-xs">{fieldErrors.last_name}</span>
              )}

              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="address"
              >
                Address:
              </label>
              <input
                className="p-2 rounded-xl border border-violet-500 focus:outline-none"
                id="address"
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => {
                  e.preventDefault();
                  setAddress(e.target.value);
                }}
              />
              {fieldErrors.address && (
                <span className="text-red-500 text-xs">{fieldErrors.address}</span>
              )}
            </form>
          </div>

          <div>
            <form className="flex flex-col" encType="multipart/form-data">
              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="phone"
              >
                Phone:
              </label>
              <input
                className="p-2 rounded-xl border border-violet-500 focus:outline-none"
                id="phone"
                type="number"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => {
                  e.preventDefault();
                  setPhone(e.target.value);
                }}
              />
              {phoneError && (
                <span className="text-red-500 text-xs">{phoneError}</span>
              )}
              {fieldErrors.phone && (
                <span className="text-red-500 text-xs">{fieldErrors.phone}</span>
              )}

              <label className="mt-4 mb-1 text-gray-600 font-semibold">
                User Type:
              </label>
              <div className="flex gap-4 items-center">
                <label>
                  <input
                    type="radio"
                    value="employer"
                    checked={user_type === "employer"}
                    onChange={(e) => setUser_type(e.target.value)}
                    required
                    className="mr-2"
                  />
                  Employer
                </label>
                <label>
                  <input
                    type="radio"
                    value="user"
                    checked={user_type === "user"}
                    onChange={(e) => setUser_type(e.target.value)}
                    required
                    className="mr-2"
                  />
                  User
                </label>
              </div>
              {fieldErrors.user_type && (
                <span className="text-red-500 text-xs">{fieldErrors.user_type}</span>
              )}

              <label
                className="mt-4 mb-1 text-gray-600 font-semibold"
                htmlFor="image"
              >
                Image:
              </label>
              <input
                className="p-2 rounded-xl border border-violet-500 focus:outline-none"
                id="image"
                type="file"
                onChange={(e) => {
                  e.preventDefault();
                  setImage(e.target.files[0]);
                }}
              />

              <button
                className="bg-violet-600 rounded-xl w-full md:w-1/2 text-white font-bold py-2 m-auto mt-4 hover:scale-105 transition-transform duration-300"
                type="submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Signup"
                )}
              </button>
            </form>

            <div className="mt-4 text-center">
              <p>
                Already have an account?{" "}
                <Link to="/login" className="ml-2 text-violet-600 font-bold">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
