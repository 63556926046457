import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../functions/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const user_type = localStorage.getItem("user_type");

  const navigate = useNavigate();

  const [notification, setNotification] = useState([]);
  const [job, setJob] = useState([]);
  const [jobapplication, setJobapplication] = useState([]);

  const [user1, setUser1] = useState([]);
  const [count, setCount] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("bg-violet-500");

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (response.data.status === 200) {
          console.log(response);
          setNotification(response.data.data);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
        } else {
          console.log("error 2");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setJobapplication(res2.data.data);
          setUser1(res2.data.user_id);
        } else {
          console.log("error 3");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchData();
  }, [baseUrl, token, count]);

  const handleClick = async (id) => {
    const data = {
      is_read: true,
    };
    try {
      const res3 = await axios.put(
        `${config.baseUrl}api/v1/ex1/notification/update/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res3.data.status === 200) {
        console.log(res3);
        setCount(id);
      } else {
        console.log("error 4");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleJobAccepted = async (id, notificationId) => {
    try {
      const res4 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (res4.data.status === 200) {
        console.log(res4);
        handleClick(notificationId);
        // navigate(`/jobuserapplydetails/${id}`);
        window.location.href = `/jobuserapplydetails/${id}`;
      } else {
        console.log("error 5");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleJobRejected = async (id, notificationId) => {
    try {
      const res5 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (res5.data.status === 200) {
        console.log(res5);
        handleClick(notificationId);

        // navigate(`/jobuserapplydetails/${id}`);
        window.location.href = `/jobuserapplydetails/${id}`;
      } else {
        console.log("error 6");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleJobAdded = async (id, notificationId) => {
    try {
      const res6 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (res6.data.status === 200) {
        console.log(res6);
        // navigate("/jobscopy", { state: { id } })
        handleClick(notificationId);

        // navigate(`/jobsuserdetails/${id}`);

        window.location.href = `/jobsuserdetails/${id}`;
      } else {
        console.log("error 7");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleJobApplication = async (id, notificationId) => {
    try {
      const res7 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (res7.data.status === 200) {
        console.log(res7);
        handleClick(notificationId);
        // navigate(`/jobapp/${id}`);
        window.location.href = `/jobapp/${id}`;
      } else {
        console.log("error 8");
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div className="bg-white rounded-lg w-80 shadow-2xl">
      <h1 className="text-center text-violet-600 font-bold mt-6">
        Notifications
      </h1>
      <div className="flex flex-col items-center">
        {user_type === "user" && (
          <div className="w-full px-2 py-1">
            <div className=" px-2 py-1 ">
              <h1 className="text-center font-bold mb-2 mt-2"></h1>

              {notification.filter(
                (item) =>
                  item.user === user1 &&
                  item.is_read === false &&
                  item.description !== "Jobapplication submitted"
              ).length === 0 ? (
                <li className="w-full text-center m-auto font-medium text-violet-800 list-none">
                  No Notification
                </li>
              ) : (
                <div className="overflow-y-auto h-72">
                  {notification
                    .filter(
                      (item) =>
                        item.user === user1 &&
                        item.is_read === false &&
                        item.description !== "Jobapplication submitted"
                    )
                    .map((item) => {
                      const bgColor =
                        item.description === "job application was accepted"
                          ? "bg-green-500"
                          : item.description === "job application was rejected"
                          ? "bg-red-500"
                          : "bg-violet-600"; // default color

                      return (
                        // <div
                        //   className="w-full mb-2"
                        //   onClick={() => {
                        //     handleClick(item.id);
                        //     item.description === "Job Added to the portal"
                        //       ? navigate("/jobscopy")
                        //       : navigate("/jobuserapply");
                        //   }}
                        // >
                        // <div
                        //   className="w-full mb-2"
                        //   onClick={() => {
                        //     if (
                        //       item.description === "Job Added to the portal"
                        //     ) {
                        //       handleJobAdded(item.job, item.id);
                        //     } else if (
                        //       item.description ===
                        //       "job application was accepted"
                        //     ) {
                        //       handleJobAccepted(item.jobapplication, item.id);
                        //     } else if (
                        //       item.description ===
                        //       "job application was rejected"
                        //     ) {
                        //       handleJobRejected(item.jobapplication, item.id);
                        //     }
                        //   }}
                        // >
                        //   {/* <div className="px-2 py-3 bg-violet-600 text-white rounded-lg text-sm"> */}
                        //   <div
                        //     className={`px-2 py-3 ${bgColor} text-white rounded-lg text-sm`}
                        //   >
                        //     <Link className="text-sm">
                        //       {job.find((itm) => itm.id === item.job).title}
                        //     </Link>
                        //     <br />
                        //     <Link className="text-xs">
                        //       {job.find((itm) => itm.id === item.job).company}
                        //     </Link>
                        //     <h1 className="hidden">{item.description}</h1>
                        //   </div>
                        // </div>

                        //                         <div
                        //   className="w-full mb-2"
                        //   onClick={() => {
                        //     if (item.description === "Job Added to the portal") {
                        //       handleJobAdded(item.job, item.id);
                        //     } else if (item.description === "job application was accepted") {
                        //       handleJobAccepted(item.jobapplication, item.id);
                        //     } else if (item.description === "job application was rejected") {
                        //       handleJobRejected(item.jobapplication, item.id);
                        //     }
                        //   }}
                        // >
                        //   <div
                        //     className={`px-2 py-3 ${bgColor} text-white rounded-lg text-sm`}
                        //   >
                        //     <Link className="text-sm">
                        //       {job.find((itm) => itm.id === item.job).title}
                        //     </Link>
                        //     <br />
                        //     {/* Show company only if description is "Job Added to the portal" */}
                        //     {item.description === "Job Added to the portal" && (
                        //       <Link className="text-xs">
                        //         {job.find((itm) => itm.id === item.job).company}
                        //       </Link>
                        //     )}
                        //     {/* Show description only when it's accepted or rejected */}
                        //     {(item.description === "job application was accepted" ||
                        //       item.description === "job application was rejected") && (
                        //       <h1 className="text-xs">{item.description}</h1>
                        //     )}
                        //   </div>
                        // </div>

                        <div
                          className="w-full mb-2"
                          onClick={() => {
                            if (
                              item.description === "Job Added to the portal"
                            ) {
                              handleJobAdded(item.job, item.id);
                            } else if (
                              item.description ===
                              "job application was accepted"
                            ) {
                              handleJobAccepted(item.jobapplication, item.id);
                            } else if (
                              item.description ===
                              "job application was rejected"
                            ) {
                              handleJobRejected(item.jobapplication, item.id);
                            }
                          }}
                        >
                          <div
                            className={`px-2 py-3 ${bgColor} text-white rounded-lg text-sm`}
                          >
                            {/* Show description first if job application was accepted or rejected */}
                            {(item.description ===
                              "job application was accepted" ||
                              item.description ===
                                "job application was rejected") && (
                              <>
                                {/* <h1 className="text-xs">{item.description}</h1> */}
                                <h1 className="text-xs font-medium">
                                  {item.description ===
                                  "job application was accepted"
                                    ? "Application Accepted"
                                    : "Application Rejected"}
                                </h1>
                                <Link className="text-sm">
                                  {job.find((itm) => itm.id === item.job).title}
                                </Link>
                              </>
                            )}

                            {/* For "Job Added to the portal", show title first, then company */}
                            {item.description === "Job Added to the portal" && (
                              <>
                                <Link className="text-sm">
                                  {job.find((itm) => itm.id === item.job).title}
                                </Link>
                                <br />
                                <Link className="text-xs">
                                  {
                                    job.find((itm) => itm.id === item.job)
                                      .company
                                  }
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        )}

        {user_type === "employer" && (
          <div className="w-full px-2 py-1">
            <div className=" px-2 py-1 ">
              <h1 className="text-center font-bold mb-2 mt-2"></h1>

              {notification.filter(
                (item) =>
                  item.user === user1 &&
                  item.is_read === false &&
                  item.description !== "Job Added to the portal"
              ).length === 0 ? (
                <li className="w-full text-center m-auto font-medium text-violet-800 list-none">
                  No Notification
                </li>
              ) : (
                <div className="overflow-y-auto h-72">
                  {notification
                    .filter(
                      (item) =>
                        item.user === user1 &&
                        item.is_read === false &&
                        item.description !== "Job Added to the portal"
                    )
                    .map((item) => {
                      return (
                        <div
                          className=" w-full mb-2 "
                          onClick={() => {
                            // handleClick(item.id);
                            if (
                              item.description === "Jobapplication submitted"
                            ) {
                              handleJobApplication(
                                item.jobapplication,
                                item.id
                              ); // Navigate to /jobapplied
                            }
                          }}
                        >
                          <div className="px-2 py-3 bg-violet-600 text-white rounded-lg text-sm">
                            <Link className="text-sm">
                              {job.find((itm) => itm.id === item.job).title}
                            </Link>
                            <br />
                            <Link className="text-xs">
                              {job.find((itm) => itm.id === item.job).company}
                            </Link>
                            <h1 className="hidden"> {item.description}</h1>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        )}

        {/* <div className="w-full px-2 py-1">
      <div className="bg-white px-2 py-1 rounded-2xl shadow-lg">
        <h1 className="text-center font-bold mb-2">Read</h1>

        {notification.filter(
          (item) => item.user === user1 && item.is_read === true
        ).length === 0 ? (
          <li className="w-full text-center mt-11 font-medium text-violet-800">
            No Read Notification
          </li>
        ) : (
          <div className="overflow-y-auto max-h-screen">
            {notification
              .filter(
                (item) => item.user === user1 && item.is_read === true
              )
              .map((item) => {
                return (
                  <div
                    className="bg-violet-300 shadow-lg w-full mb-2 px-2 py-1 rounded-md"
                    onClick={() => {
                      item.description === "Job Added to the portal"
                        ? navigate("/jobscopy")
                        : navigate("/jobuserapply");
                    }}
                  >
                    <h3 className="">
                      {job.find((itm) => itm.id === item.job).title} {""}{" "}
                      {item.description}
                    </h3>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div> */}
      </div>
    </div>
  );
}
