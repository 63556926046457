import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import add from "../images/add.png";
import apply from "../images/apply.png";
import select from "../images/select.png";
import logout from "../images/logout.png";
import joblist from "../images/joblist.png";
import config from "../functions/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pro_pic from "../images/pro_pic.jpeg";
import Notification from "./Notification";

export default function Jobapplied() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [array, setArray] = useState([]);
  const [job, setJob] = useState([]);
  const [user, setUser] = useState([]);

  const [user1, setUser1] = useState([]);
  const [user3, setUser3] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const [notificationCount, setNotificationCount] = useState(0);
  const [user2, setUser2] = useState([]);
  const [notification, setNotification] = useState([]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Add state for mobile menu

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen); // Toggle the menu state on button click
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  // State to track the status of each job application (accepted, rejected)
  const [status, setStatus] = useState({});

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobapp`, {
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res2 = await axios.get(`${baseUrl}api/v1/ex1/user`, {
        const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/user`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        const res6 = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response);
          setArray(response.data.data);
         
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
          setUser1(res1.data.user);
        } else {
          console.log("error 1");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setUser(res2.data.data);
          
        } else {
          console.log("error 2");
        }

        if (res6.data.status === 200) {
          console.log(res6);
          setNotification(res6.data.data);
          setUser2(res6.data.user_id);
          const count = res6.data.data.filter(
            (item) =>
              item.user === res6.data.user_id &&
              item.is_read === false &&
              item.description === "Jobapplication submitted"
          );
          setNotificationCount(count.length);
        } else {
          console.log("error 7");
        }
        
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token]);

  const handleClick1 = async (id) => {
    const data = {
      is_accepted: true,
    };
    try {
      // const res3 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/accept/${id}`,
      //   data,

      const res3 = await axios.put(
        `${config.baseUrl}api/v1/ex1/jobapp/accept/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res3.data.status === 200) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [id]: { accepted: true, rejected: false },
        }));
        console.log(res3);
        toast.success("Application Selected", {
          autoclose: 1100,
          position: "top-right",
        });
      } else {
        console.log("error 3");
        toast.error("Error", {
          autoclose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1100,
        position: "top-right",
      });
    }
  };

  const handleClick2 = async (id) => {
    const data = {
      is_rejected: true,
    };
    try {
      // const res4 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/reject/${id}`,
      //   data,

      const res4 = await axios.put(
        `${config.baseUrl}api/v1/ex1/jobapp/reject/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res4.data.status === 200) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [id]: { accepted: false, rejected: true },
        }));
        console.log(res4);
        toast.success("Application Rejected", {
          autoclose: 1100,
          position: "top-right",
        });
      } else {
        console.log("error 4");
        toast.error("Error", {
          autoclose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1100,
        position: "top-right",
      });
    }
  };

  const handleClick3 = async (id) => {
    const data = {
      is_applied: false,
      is_rejected: false,
      is_allowed: false,
    };
    try {
      // const res5 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/readd/${id}`,
      //   data,

      const res5 = await axios.put(
        `${config.baseUrl}api/v1/ex1/jobapp/readd/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res5.data.status === 200) {
        console.log(res5);
        toast.success("Added to Jobs Successfully", {
          autoclose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/employerd");
          },
        });
      } else {
        console.log("error 5");
        toast.error("Error", {
          autoclose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1100,
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <div className="">
        {/* <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div> */}

        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4 fixed top-0 left-0 right-0">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <div className="flex items-center gap-3">
            <div className="relative">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggleNotification();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                  />
                </svg>

                <span className="absolute top-0 right-0 left-4 block w-4 h-4 text-xs text-white bg-red-600 rounded-full text-center">
                  {notificationCount}
                </span>
              </button>
            </div>

            <h5 className="text-3xl font-bold text-white">Careerhub</h5>
          </div>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out fixed top-[69px] left-0 w-36 h-62 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-[16px] text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/employerd">Jobs</Link>
            </li>
            <li>
              <Link to="/jobs/add">Add Jobs</Link>
            </li>
            <li>
              <Link to="/jobapplied">Applications</Link>
            </li>
            {/* <li>
              <Link to="/jobselected">Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex">
        <nav>
          {/* <div className="sm:w-[200px] md:w-[280px] h-lvh bg-violet-600"> */}
          <div className="hidden md:block w-40 md:w-56 h-full bg-violet-600 fixed top-0 left-0 bottom-0">
            <div className="flex items-center p-5">
              {/* <img src={logo} width={50} height={50} /> */}
              {/* <h1 className="ml-3">GOOD JOBS</h1> */}
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={profile} width={30} height={30} /> */}

                {/* <a href="/userprofile" className="ml-5">
                  Profile
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}

                {/* <a href="/employerd" className="ml-5">
                  Jobs
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/employerd" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={add} width={30} height={30} /> */}

                {/* <a href="/jobs/add" className="ml-5">
                  Add Jobs
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <Link to="/jobs/add" className="ml-5 font-bold text-white">
                  Add Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}

                {/* <a href="/jobapplied" className="ml-5">
                  Jobs Applied
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobapplied" className="ml-5 font-bold text-white">
                  {" "}
                  Applications
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link to="/jobselected" className="ml-5 font-bold text-white">
                  Applications Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={logout} width={30} height={30} /> */}

                {/* <a href="/" className="ml-5">
                  Logout
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bg-slate-200 w-full min-h-screen">
          <div className="hidden md:block w-full px-4 py-4">
            <div className="flex justify-end items-center gap-3">
              <div className="mr-3">
                <button
                  // onClick={() => navigate("/notification")}

                  onClick={(e) => {
                    e.preventDefault();
                    toggleNotification();
                  }}
                >
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 text-violet-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                      />
                    </svg>

                    <span className="absolute top-0 right-0 left-5 block w-5 h-5 text-xs text-white bg-red-600 rounded-full text-center">
                      {notificationCount}
                    </span>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <img
                    // src={`${baseUrl}${user.image}`}
                    src={user1.image ? `${baseUrl}${user1.image}` : pro_pic}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="md:ml-64 mx-auto mt-20 md:mt-0">
            <h1 className="text-center text-xl text-violet-600 font-bold mt-1">
              Applications
            </h1>
            <ul className="flex flex-wrap gap-6 p-6 text-center">

            {array
    .filter((item) => job.find(itm => itm.id === item.job)?.user === user2)
    .length === 0 ? (
    <li className="w-full text-center p-4 text-xl font-semibold text-violet-600">
      No Applications
    </li>
  ) : (

              array
                .filter((item) => job.find(itm=>itm.id===item.job)?.user === user2 )
                

                .map((item) => {
                  const isAccepted = status[item.id]?.accepted;
                  const isRejected = status[item.id]?.rejected;

                  let statusText = "Pending"; // Default to Pending
                  if (item.is_accepted === true) {
                    statusText = "Accepted";
                  } else if (item.is_rejected === true) {
                    statusText = "Rejected";
                  }

                  return (
                    <li
                      key={item.id}
                      className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 bg-white shadow-lg rounded-lg border border-gray-200 "
                    >
                      <div>
                        <h1 className="text-xl font-semibold text-gray-800">
                          {job.find((itm) => itm.id === item.job).title}
                        </h1>
                        <h2 className="text-md text-gray-600">
                          Company :{" "}
                          {job.find((itm) => itm.id === item.job).company}
                        </h2>
                        <h2 className="text-md text-gray-600">
                          Location :{" "}
                          {job.find((itm) => itm.id === item.job).city}
                        </h2>
                        <h2 className="text-md text-gray-600">
                          Salary :{" "}
                          {job.find((itm) => itm.id === item.job).salary}
                        </h2>
                        <h2 className="text-md text-gray-700">
                          Applied by :{" "}
                          {user.find((itm) => itm.id === item.user).first_name}{" "}
                          {user.find((itm) => itm.id === item.user).last_name}
                        </h2>
                      </div>

                      <div className="flex justify-center gap-3">
                        <h2 className="text-md text-gray-600">Status :</h2>
                        <h3
                          className={`font-bold text-${
                            statusText === "Accepted"
                              ? "green"
                              : statusText === "Rejected"
                              ? "red"
                              : "yellow"
                          }-500`}
                        >
                          {statusText}
                        </h3>
                      </div>

                      <button className="bg-violet-600 px-2 py-1 mt-3 text-white font-bold rounded-3xl">
                       
                        <Link
                          to={
                            statusText === "Pending"
                              ? `/jobapp/${item.id}`
                              : `/jobselecteddetails/${item.id}`
                          }
                        >
                          View Application
                        </Link>
                      </button>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`hidden md:block fixed top-10 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>

      <div
        className={`md:hidden fixed top-11 right-0 transform transition-all duration-500 ease-in-out ${
          isNotificationOpen ? "translate-x-0" : "translate-x-full z-10"
        }`}
        // style={{ zIndex: 9999 }} // Ensure the notification is above other elements
      >
        <Notification />
      </div>
    </div>
  );
}
